import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import GoogleAdsButton from './googleAdsButton/GoogleAdsButton';
import DV360Button from './dv360Button/DV360Button';
import CM360Button from './cm360Button/CM360Button';
import GoogleAnalyticsButton from './googleAnalyticsButton/GoogleAnalyticsButton';
import FacebookAdsButton from './facebookAdsButton/FacebookAdsButton';
import SnapchatAdsButton from './snapchatAdsButton/SnapchatAdsButton';
import TikTokAdsButton from './tikTokAdsButton/TikTokAdsButton';
import PinterestAdsButton from './pinterestAdsButton/PinterestAdsButton';
import AmazonAdsButton from './amazonAdsButton/AmazonAdsButton';
import ReadpeakButton from './readpeakButton/ReadpeakButton';
import StrossleButton from './strossleButton/StrossleButton';
import AppleSearchAdsButton from './appleSearchAdsButton/AppleSearchAdsButton';
import MicrosoftInvestButton from './microsoftInvestButton/MicrosoftInvestButton';
import TaboolaAdsButton from './taboolaAdsButton/TaboolaAdsButton';
import LinkedInAdsButton from './linkedInAdsButton/LinkedInAdsButton';

import MicrosoftAdvertisingButton from './microsoftAdvertisingButton/MicrosoftAdvertisingButton';

import AdformButton from './adformButton/AdformButton';

import CustomDataSourceButton from './customDataSourceButton/CustomDataSourceButton';

import StorageIcon from '@mui/icons-material/Storage';

import { useMutation } from '@apollo/client';
import { getDataSourceOAuthUrlMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AuthContext } from '../../../../../context/AuthContext';
import { AccountContext } from '../../../../../context/AccountContext';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  setModal,
  setOpenDataSourceTermsModal
}) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const { isDemoMode } = useContext(InterfaceContext);

  //   const [open, setOpen] = React.useState(false);

  const [addDataSource, { called }] = useMutation(
    getDataSourceOAuthUrlMutation
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickHandler = (e, dataSource) => {
    setOpenDataSourceTermsModal(true);

    switch (dataSource) {
      case 'adform':
        setModal('adform_terms_and_conditions');
        break;
      case 'facebook_ads':
        setModal('facebook_ads_terms_and_conditions');
        break;

      case 'snapchat_ads':
        setModal('snapchat_ads_terms_and_conditions');
        break;

      case 'tiktok_ads':
        setModal('tiktok_ads_terms_and_conditions');
        break;

      case 'pinterest_ads':
        setModal('pinterest_ads_terms_and_conditions');
        break;

      case 'amazon_ads':
        setModal('amazon_ads_terms_and_conditions');
        break;

      case 'readpeak':
        setModal('readpeak_terms_and_conditions');
        break;

      case 'strossle':
        setModal('strossle_terms_and_conditions');
        break;

      case 'apple_search_ads':
        setModal('apple_search_ads_terms_and_conditions');
        break;
      case 'microsoft_invest':
        setModal('microsoft_invest_terms_and_conditions');
        break;

      case 'taboola_ads':
        setModal('taboola_ads_terms_and_conditions');
        break;

      case 'linkedin_ads':
        setModal('linkedin_ads_terms_and_conditions');
        break;
      case 'microsoft_advertising':
        setModal('microsoft_advertising_terms_and_conditions');
        break;

      case 'google_ads':
        setModal('google_ads_terms_and_conditions');
        break;
      case 'google_analytics':
        setModal('google_analytics_terms_and_conditions');
        break;
      case 'dv360':
        setModal('dv360_terms_and_conditions');
        break;
      case 'cm360':
        setModal('cm360_terms_and_conditions');
        break;
      case 'custom_data_source':
        setModal('custom_data_source_terms_and_conditions');
        break;
    }

    setOpen(false);
  };

  const buttonStyle = {
    justifyContent: 'center',
    margin: '10px 10px',
    display: 'inline-block'
  };

  const content = (
    <div>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'inline-block',
            textAlign: 'center',
            // flexDirection: 'column',
            // width: '900px',
            // gridTemplateRows: ['system_admin', 'debug'].includes(user.role)
            //   ? '80px 80px 80px 80px 80px 80px'
            //   : '80px 80px 80px 80px',
            marginTop: '20px'
          }}
        >
          {/* <div style={{display: 'grid', gridRowStart: 1, gridRowEnd: 1}}>
                    <TextField value={name} onChange={(e)=>onChangeHandler(e, 'name')} id="standard-basic" label="Name" />
                </div> */}
          {/* <div style={{
                  height: '150px', 
                  width: '150px', 
                  borderRadius: '5px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  background: '#5F6368',
                  paddingTop: '15%',
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
                  // backgroundImage: 'linear-gradient(to bottom right, rgba(150,150,150,0.5), rgba(151,150,150,0.4))'
                  }}>
                    <GoogleAdsButton />

                  </div> */}

          <div style={buttonStyle}>
            <AdformButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <AmazonAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <AppleSearchAdsButton onClickHandler={onClickHandler} />
          </div>

          <div style={buttonStyle}>
            <MicrosoftAdvertisingButton onClickHandler={onClickHandler} />
          </div>

          <div style={buttonStyle}>
            <CM360Button onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <DV360Button onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <FacebookAdsButton onClickHandler={onClickHandler} />
          </div>

          <div style={buttonStyle}>
            <GoogleAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <LinkedInAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <MicrosoftInvestButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <PinterestAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <ReadpeakButton onClickHandler={onClickHandler} />
          </div>

          <div style={buttonStyle}>
            <SnapchatAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <StrossleButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <TaboolaAdsButton onClickHandler={onClickHandler} />
          </div>
          <div style={buttonStyle}>
            <TikTokAdsButton onClickHandler={onClickHandler} />
          </div>

          {['system_admin', 'debug'].includes(user.role) && !isDemoMode && (
            <>
              {/* <div style={buttonStyle}>
                <DV360Button onClickHandler={onClickHandler} />
              </div> */}

              {/* <div style={buttonStyle}>
                <CM360Button onClickHandler={onClickHandler} />
              </div> */}

              <div style={buttonStyle}>
                <CustomDataSourceButton onClickHandler={onClickHandler} />
              </div>
            </>
          )}

          {(currentManagerAccount.isGoogleAnalyticsOn ||
            ['system_admin', 'debug'].includes(user.role)) && (
            <div style={buttonStyle}>
              <GoogleAnalyticsButton onClickHandler={onClickHandler} />
            </div>
          )}
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        PaperProps={{
          style: {
            width: 'fit-content'
            // maxWidth: '900px'
          }
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Connectors
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
