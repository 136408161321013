import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportsQuery } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

function ReportList({ selectedReport, setSelectedReport }) {
  const theme = useTheme();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const [reports, setReports] = useState();

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds
    }
  };

  const [loadReports, { called, loading, data }] = useLazyQuery(reportsQuery, {
    variables,
    fetchPolicy: 'no-cache'
    // skip: !currentManagerAccountId && skip
  });

  useEffect(() => {
    if (currentManagerAccountId && currentAccountIds) {
      loadReports();
    }
  }, [currentManagerAccountId, currentAccountIds]);

  useEffect(() => {
    if (data?.reports && called) {
      // setWarnings(
      //   data?.dataSourceErrors?.filter((obj) => obj.status !== 'DEACTIVATED')
      // );
      setReports(data?.reports);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedReport && reports?.length) {
      setSelectedReport(reports[0]);
    }
  }, [reports]);

  return (
    <div
      style={{
        height: '100%',
        width: '400px',
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 10px',
        padding: '10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(0,0,0,0.1)'
            : 'rgba(255,255,255,0.3)'
      }}
    >
      {(reports || []).map((report) => (
        <Button
          size="small"
          style={{
            // gridRowStart: 2,
            // gridRowEnd: 2,
            height: '40px',
            justifyContent: 'flex-start'
            // background:
            //   (selectedReport.type === report.type ||
            //     selectedReport._id === report._id) &&
            //   stylesStoredInReduxManagerAccountSettings.selectedListButton
          }}
          onClick={() => setSelectedReport(report)}
        >
          {report?.name}
        </Button>
      ))}
    </div>
  );
}

export default ReportList;
