import html2canvas from 'html2canvas';

export const createThumbnail = async (
  id = 'report-builder-page',
  scale = 0.2
) => {
  const domElement = document.getElementById(id);
  if (!domElement) {
    return;
  }

  //   const oldOpacity = domElement.style.opacity;
  //   domElement.style.opacity = 1;

  let thumbnail = '';

  await html2canvas(domElement, {
    scale: scale,
    // scale: window.devicePixelRatio,
    logging: true,
    letterRendering: true, //scale,
    allowTaint: false,
    useCORS: true
  }).then((canvas) => {
    // var image = canvas.toDataURL('image/png', 1.0);
    var wid;
    var hgt;
    var image = canvas.toDataURL(
      'image/png',
      (wid = canvas.width),
      (hgt = canvas.height)
    );

    // var doc = new jsPDF('l', 'px', [wid, hgt], true);
    // var doc = new jsPDF('p', 'pt', [wid, hgt], true);
    // var doc = new jsPDF('p', 'pt','a4',true);

    // console.log('ExportPDF>wid: ', wid);
    // console.log('ExportPDF>hgt: ', hgt);

    // var fileName = currentExport.split('Export')[0] + '.png';
    const fileName = 'NexusTable_export.pdf';
    // saveAs(image, fileName);
    // console.log('image: ', image);

    // var doc = new jsPDF('l', 'px', 'a4');
    // const padding = 0;
    // var hratio = hgt / wid;
    // var width = doc.internal.pageSize.width - padding * 2;
    // var height = width * hratio;

    console.log('createThumbnail>image: ', image);

    thumbnail = image;
  });
  return thumbnail;
};
