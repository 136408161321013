import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

export const pieChart = (widget, listOfFilteredDataSourceRows) => {
  const key = widget.metrics?.[0]?.metric;
  let widgetGroupByKey = '';

  switch (widget.dimensions[0]) {
    case 'documentType':
      widgetGroupByKey = 'documentType';
      break;
    case 'name':
      widgetGroupByKey = 'name';
      break;
  }
  const testGroupBy = Object.groupBy(
    listOfFilteredDataSourceRows,
    (row) => row[widgetGroupByKey]
  );

  // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

  const dimensionKeys = Object.keys(testGroupBy);

  const newSeriesData = [];
  for (let dimensionKey of dimensionKeys) {
    let dimensionsValue = 0;

    for (let row of testGroupBy[dimensionKey]) {
      dimensionsValue += row[key];
    }
    let seriesData = {
      // label: documentTypeToNameDict[dimensionKey],
      value: dimensionsValue
    };

    switch (widgetGroupByKey) {
      case 'documentType':
        seriesData.label =
          documentTypeToShortNameDict?.[dimensionKey]?.slice(0, 15) +
          (documentTypeToShortNameDict?.[dimensionKey]?.length > 15
            ? '...'
            : '');
        break;

      case 'name':
        seriesData.label =
          dimensionKey?.slice(0, 20) + (dimensionKey?.length > 20 ? '...' : '');
        break;
    }
    // console.log('seriesData.label: ', seriesData.label);
    newSeriesData.push(seriesData);
  }

  let totalValue = 0;
  for (let row of newSeriesData) {
    totalValue += row.value || null;
  }

  for (let row of newSeriesData) {
    row.share = Math.round((row.value / totalValue) * 100);
    row.value = Math.round(row.value);
  }
  // console.log('totalValue: ', totalValue);

  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    data: newSeriesData
  };

  return copyOfWidget;
};
