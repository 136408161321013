import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';

function ReportPage({ reportPage, selectedReportPage, setSelectedReportPage }) {
  const theme = useTheme();
  console.log(
    "reportPage?.height?.split('px')[0]): ",
    reportPage?.height?.split('px')[0]
  );
  const aspectRatio =
    reportPage?.width?.split('px')[0] / reportPage?.height?.split('px')[0];
  return (
    <Button
      style={{
        margin: '10px 10px',
        height: '200px',
        aspectRatio: aspectRatio,
        boxShadow:
          theme?.palette?.mode === 'dark'
            ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' //'0px 3px 1px -2px rgb(75 75 150 / 20%), 0px 2px 2px 0px rgb(75 75 150 / 14%), 0px 1px 5px 0px rgb(75 75 150 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        // gridRowStart: 2,
        // gridRowEnd: 2,
        // height: '40px',
        // justifyContent: 'flex-start'
        // background:
        //   (selectedReport.type === report.type ||
        //     selectedReport._id === report._id) &&
        //   stylesStoredInReduxManagerAccountSettings.selectedListButton
      }}
      onClick={() => setSelectedReportPage(reportPage)}
    >
      <div
        style={{
          margin: '5px',
          borderRadius: '5px',
          height: '200px',
          aspectRatio: aspectRatio,
          // width: '400px',
          backgroundSize: 'cover',
          // width: '170px',

          backgroundImage: `url(${reportPage.thumbnailImageDataUri})`,
          backgroundRepeat: 'no-repeat',
          border:
            reportPage?._id === selectedReportPage?._id
              ? theme?.palette?.mode === 'dark'
                ? '1px solid rgba(255,255,255,1)'
                : 'red'
              : '1px solid rgba(255,255,255,0)'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            padding: '1px 7px',
            background: '#a7268344',
            borderRadius: '0px 5px 0px 5px',
            fontSize: '10px',
            fontWeight: 'bold'
          }}
        >
          {reportPage?.name}
        </div>
      </div>
      {/* <img src={`${reportPage.thumbnailImageDataUri}`} /> */}
      {/* {reportPage?.name} */}
    </Button>
  );
}

export default ReportPage;
