import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportAssetQuery } from '../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { DialogContext } from '../../../../context/DialogContext';

function ReportAssetInfo({ selectedReportAsset, setSelectedReportAsset }) {
  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const {
    widgets,
    setWidgets,
    widgetIdThatTriggeredContextMenu,
    updateWidget
  } = useContext(ReportBuilderContext);

  const { reportBuilderAssetDialogOpen, setReportBuilderAssetDialogOpen } =
    useContext(DialogContext);

  const [reportAsset, setReportAsset] = useState();

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      reportAssetId: selectedReportAsset?._id
    }
  };

  const [loadReportAsset, { called, loading, data }] = useLazyQuery(
    reportAssetQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (currentManagerAccountId && selectedReportAsset) {
      loadReportAsset();
    }
  }, [currentManagerAccountId, selectedReportAsset]);

  useEffect(() => {
    if (data?.reportAsset && called) {
      setReportAsset(data?.reportAsset);
    }
  }, [data]);

  const aspectRatio =
    reportAsset?.width?.split('px')[0] / reportAsset?.height?.split('px')[0];

  // console.log('selectedReportAsset: ', selectedReportAsset);
  // console.log('reportAsset: ', reportAsset);

  const onClickImportHandler = () => {
    const widget = widgets.filter(
      (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
    )[0];

    updateWidget({
      ...widget,
      imageAssetId: reportAsset._id,
      naturalHeight: reportAsset.naturalHeight,
      naturalWidth: reportAsset.naturalWidth,
      imageOriginalAspectRatio: reportAsset.imageOriginalAspectRatio,
      imageDataUri: reportAsset.imageDataUri
    });

    setReportBuilderAssetDialogOpen(false);
    // console.log('ReportAssetInfo>widget: ', widget);
  };

  if (!reportAsset?.imageDataUri) {
    return <div></div>;
  }
  return (
    <div
      style={{
        height: '100%',
        width: '1200px',
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 10px',
        padding: '10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)',
        overflowY: 'scroll'
      }}
    >
      <img
        draggable={false}
        src={reportAsset?.imageDataUri}
        style={{
          objectFit: 'contain',
          // backgroundImage: `url(${reportAsset.imageDataUri})`,
          // backgroundRepeat: 'no-repeat',
          //   aspectRatio: reportAsset.imageOriginalAspectRatio,
          width: '100%',
          maxHeight: '400px',
          maxWidth: '100%',
          borderRadius: '5px'
          //   //   height: '300px'
        }}
      />
      <div
        style={{
          minHeight: '200px',
          // margin: '10px',
          marginTop: '10px',
          padding: '10px',
          paddingLeft: '30px',
          borderRadius: '5px',
          background: 'rgba(150,150,150,0.3)',
          fontSize: '14px',
          fontWeight: 'bold',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '50px',
              marginRight: '20px'
            }}
          >
            Name:
          </span>{' '}
          <span>{reportAsset?.name}</span>
        </div>
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '50px',
              marginRight: '20px'
            }}
          >
            Width:
          </span>
          <span>{reportAsset?.naturalWidth}</span>
        </div>
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '50px',
              marginRight: '20px'
            }}
          >
            Height:
          </span>
          {reportAsset?.naturalHeight}
        </div>
      </div>
      <div
        style={{
          margin: '10px 0px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end'
        }}
      >
        <Button onClick={onClickImportHandler} variant="outlined">
          Import
        </Button>
      </div>
    </div>
  );
}

export default ReportAssetInfo;
