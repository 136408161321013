import React, { useEffect, useState, useContext, memo } from 'react';

import { useTheme } from '@emotion/react';

import FolderIcon from '@mui/icons-material/Folder';

import { AccountContext } from '../../../../context/AccountContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { DataContext } from '../../../../context/DataContext';

import { MutationContext } from '../../../../context/MutationContext';

import { DialogContext } from '../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import Tooltip from '@mui/material/Tooltip';

import IconComponent from '../IconComponent';

import EventIcon from '@mui/icons-material/Event';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import rgba2hex from '../../roitable_functions/rgba2hex';

import { useInView } from 'react-intersection-observer';

// import './../reportPage.css';

function ReportPageListPage({ zoom, reportPage }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { currentManagerAccount } = useContext(AccountContext);
  const { altHeld } = useContext(InterfaceContext);
  const { updateReportPages } = useContext(MutationContext);
  const theme = useTheme();

  const [textInput, setTextInput] = useState('');

  const {} = useContext(DataContext);

  const { reportBuilderListScale, setReportBuilderListScale } =
    useContext(DialogContext);

  const {
    selectedPageId,
    setSelectedPageId,
    setReportPageIdThatTriggeredContextMenu,
    setReportPageListContextMenuAnchorPosition: setContextMenuAnchorPosition
  } = useContext(ReportBuilderContext);

  const onChangeHandler = (e) => {
    // console.log('e.target.value: ', e.target.value);
    setTextInput(e.target.value);
  };

  const [isEditMode, setIsEditMode] = useState();

  let height =
    ((reportPage.endDateTime - reportPage.startDateTime) / 60000) * zoom;

  let border = undefined;

  // ###################### on drop ############################

  // const onDropHandler = async (e) => {
  //   if (!reportPage.isMutable) {
  //     return;
  //   }

  //   e.stopPropagation();
  //   e.preventDefault();
  //   // console.log('debug95>ReportPageer>onDrop>e: ', e);
  //   // e.stopPropagation();
  //   // e.preventDefault();
  //   const droppedItemString = e.dataTransfer.getData('droppedItem');
  //   let droppedItem = {};
  //   try {
  //     droppedItem = JSON.parse(droppedItemString);
  //   } catch (error) {
  //     // setIsMouseDown(false);
  //     return;
  //   }

  //   if (
  //     reportPage.accountId &&
  //     droppedItem.accountId &&
  //     String(reportPage.accountId) !== String(droppedItem.accountId)
  //   ) {
  //     return;
  //   }
  //   // console.log('debug95>row65');
  //   if (!droppedItem?.id) {
  //     return;
  //   }
  //   // console.log('debug95>row68');

  //   let newArrayOfDocumentIds = [];
  //   const listOfUniqueDocumentIds = [];
  //   if (droppedItem.type !== 'account') {
  //     newArrayOfDocumentIds = [
  //       ...(reportPage.nexusDocuments.map((doc) => String(doc._id)) || []),
  //       droppedItem.id
  //     ];
  //     for (let docId of newArrayOfDocumentIds) {
  //       if (!listOfUniqueDocumentIds.includes(String(docId))) {
  //         listOfUniqueDocumentIds.push(String(docId));
  //       }
  //     }
  //   }

  //   const input = {
  //     managerAccountId: currentManagerAccount._id,
  //     // selectedUserId: selectedUser?._id,
  //     updateKeys:
  //       droppedItem.type === 'account' ? ['accountId'] : ['nexusDocumentIds'],
  //     reportPagees: [
  //       {
  //         _id: reportPage._id,
  //         accountId: droppedItem.accountId,
  //         nexusDocumentIds:
  //           droppedItem.type !== 'account' ? listOfUniqueDocumentIds : []
  //       }
  //     ],
  //     selectedReportPageListId: selectedReportPageList._id
  //   };
  //   // console.log('debug95>row68>input');

  //   await updateReportPages({ variables: { input } });
  // };

  // const onDragOverHandler = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  // ###########################################################

  // const dragOver = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  const onDragEndHandler = (e, value) => {
    // setIsDragging(false);
    // setFinalreportBuilderListScale(reportBuilderListScale);
    // console.log('dragEnd');
    const target = e.target;
    setTimeout(() => {
      var delayInMilliseconds = 1;
      setTimeout(function () {
        target.style.display = 'flex';
        //your code to be executed after 1 second
      }, delayInMilliseconds);
    }, 0);
  };

  // ####################################################

  const onDragStartHandler = (e) => {
    // if (!reportPage.isMutable) {
    //   return;
    // }
    e.stopPropagation();
    // e.preventDefault();

    // setIsDragging(true);
    // setFinalreportBuilderListScale(1);
    // console.log('ReportPage>onDragStart>e: ', e);
    // console.log('reportBuilderListScale: ', reportBuilderListScale);
    // setIsMouseDown(false);
    const target = e.target;
    // target.style.transform = `scale(0.7))`;
    target.style.zoom = reportBuilderListScale;

    // target.style.background = `green`;

    // transform: `scale(${reportBuilderListScale})`,
    // transformOrigin: 'right',

    // console.log('target: ', target);
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        id: target.id, //item._id,
        type: 'reportPage',
        documentType: 'reportPage',
        status: reportPage.status,
        // eNativeEventOffsetY: e.nativeEvent.offsetY * reportBuilderListScale,
        startDateTime: reportPage.startDateTime,
        endDateTime: reportPage.endDateTime
      })
    );

    setTimeout(() => {
      target.style.display = 'none';
      target.style.zoom = 0;
    }, 0);
  };

  const onContextHandler = (e, reportPage) => {
    // console.log('onContextHandler>e: ', e);

    e.preventDefault();
    setReportPageIdThatTriggeredContextMenu(reportPage.id);
    setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
  };

  // ###########################################################

  // useEffect(async () => {
  //   const domElement = document.getElementById('page_' + page._id);

  //   const oldOpacity = domElement.style.opacity;
  //   domElement.style.opacity = 1;

  //   await html2canvas(domElement, {
  //     scale: 1.6,
  //     // scale: window.devicePixelRatio,
  //     logging: true,
  //     letterRendering: 2,
  //     allowTaint: false,
  //     useCORS: true
  //   }).then((canvas) => {
  //     // var image = canvas.toDataURL('image/png', 1.0);
  //     var wid;
  //     var hgt;
  //     var image = canvas.toDataURL(
  //       'image/png',
  //       (wid = canvas.width),
  //       (hgt = canvas.height)
  //     );

  //     // var doc = new jsPDF('l', 'px', [wid, hgt], true);
  //     // var doc = new jsPDF('p', 'pt', [wid, hgt], true);
  //     // var doc = new jsPDF('p', 'pt','a4',true);

  //     // console.log('ExportPDF>wid: ', wid);
  //     // console.log('ExportPDF>hgt: ', hgt);

  //     // var fileName = currentExport.split('Export')[0] + '.png';
  //     const fileName = 'NexusTable_export.pdf';
  //     // saveAs(image, fileName);
  //     // console.log('image: ', image);

  //     // var doc = new jsPDF('l', 'px', 'a4');
  //     // const padding = 0;
  //     // var hratio = hgt / wid;
  //     // var width = doc.internal.pageSize.width - padding * 2;
  //     // var height = width * hratio;
  //   });
  // });

  // console.log('reportPage: ', reportPage);

  // ################### colors #####################

  const accountColorAsRGBA = reportPage.accountColor
    ? reportPage.accountColor
    : theme?.palette?.mode === 'dark'
    ? 'rgba(255,255,255,0.3)'
    : 'rgba(200,200,255,1)';

  const accountColorAsHex = '#' + rgba2hex(accountColorAsRGBA);

  const transparentAccountColorAsHex =
    accountColorAsHex.slice(0, 7) +
    (theme?.palette?.mode === 'dark' ? '05' : '44');

  // ###########################################################

  const deadline =
    reportPage?.deadline &&
    reportPage?.deadline?.toLocaleString()?.slice(0, -3);

  return (
    <div
      onClick={() => {
        // console.log('cliiiiick!');
        setSelectedPageId(reportPage.id);
      }}
      ref={ref}
      id={reportPage.id}
      onContextMenu={(e) => onContextHandler(e, reportPage)}
      // onDrop={(e) => e.stopPropagation()}
      // onDrop={onDropHandler}
      // onDragOver={onDragOverHandler}
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      // onDrop={onDropHandler}
      // onDragOver={onDragOverHandler}
      // draggable={altHeld}
      draggable
      style={{
        visibility: !inView && 'hidden',
        marginTop: '3px',
        marginBottom: '3px',
        overflow: 'hidden',
        userSelect: 'none',

        // background:
        //   theme?.palette?.mode === 'dark'
        //     ? 'rgb(240 240 255 / 20%)' //'rgba(230,230,255,0.15)'
        //     : 'rgba(255, 255, 255, 0.5)', // 'rgba(200,200,255,0.3)',
        backgroundImage:
          inView &&
          (`url(${reportPage.thumbnailImageDataUri})` ||
            (theme?.palette?.mode === 'dark'
              ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
              : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.5)'})`)),
        backgroundSize: 'cover',
        width: '170px',

        aspectRatio: '16/9',

        borderRadius: '5px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // cursor: reportPage.isMutable ? 'grab' : 'not-allowed',
        border:
          theme?.palette?.mode === 'dark' && '1px solid rgb(235 235 255 / 10%)', // '1px solid rgba(255,255,255,0.1)',
        border: selectedPageId === reportPage.id && '2px solid red',

        backdropFilter: 'blur(10px)',
        boxShadow:
          theme?.palette?.mode === 'dark'
            ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' //'0px 3px 1px -2px rgb(75 75 150 / 20%), 0px 2px 2px 0px rgb(75 75 150 / 14%), 0px 1px 5px 0px rgb(75 75 150 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
    >
      {reportPage?.isDataFrozen && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            marginRight: '3px'
          }}
        >
          <AcUnitIcon
            style={{
              color:
                theme?.palette?.mode === 'dark'
                  ? 'rgba(184,212,235,0.9)'
                  : 'rgba(184,212,235,0.9)', //'rgba(150,150,150,0.5)',
              fontSize: '18px'
            }}
          />
        </div>
      )}
    </div>
  );
}

export default memo(ReportPageListPage);
