import React, { useContext, useState, useEffect, useRef } from 'react';
import { DialogContext } from '../../../../context/DialogContext';

import { AuthContext } from '../../../../context/AuthContext';
import { AccountContext } from '../../../../context/AccountContext';
import { MutationContext } from '../../../../context/MutationContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { DataContext } from '../../../../context/DataContext';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@mui/material';

import ReportBuilderPage from './ReportBuilderThumbnailPage';

import { v4 as uuidv4 } from 'uuid';

import { useTheme } from '@emotion/react';

import formatDateYYYYMMDD from '../../roitable_functions/formatDateYYYYMMDD';

import TimeboxerMenu from '../menu/TimeboxerMenu';

import AssessmentIcon from '@mui/icons-material/Assessment';

// import TimeboxerViewDialog from './report/TimeboxerViewDialog';

// import UserSelectPopover from './user-select-menu/UserSelectPopover';
import ReportBuilderContextMenu from '../context-menu/ReportBuilderContextMenu';

// import TimeboxListSelectPopover from './timebox-list-select-menu/TimeboxListSelectPopover';

import ReportPageListContextMenu from './context-menu/ReportPageListContextMenu';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import QueueIcon from '@mui/icons-material/Queue';
import CloseIcon from '@mui/icons-material/Close';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { timeboxListTimeboxesQuery } from '../../../../graphql/queries';

// import SaveTemplatePopper from './../SaveTemplatePopper';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

function ReportBuilderPageList({ selectedUser, numDays, right }) {
  const pollTime = 5 * 60 * 1000;

  const [isDragging, setIsDragging] = useState(false);

  const { report } = useContext(DataContext);

  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] =
    React.useState(null);

  const ref = useRef();

  const reportBuilderListRef = useRef();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const {
    isReportBuilderOpen: isTimeboxerOpen,
    isReportBuilderPageListOpen: isTimeboxListOpen,
    setIsReportBuilderPageListOpen: setIsTimeboxListOpen,
    // reportBuilderListScale,
    // setReportBuilderListScale

    reportBuilderListScale,
    setReportBuilderListScale
  } = useContext(DialogContext);
  const { currentManagerAccount } = useContext(AccountContext);

  let zoom = 2.5;

  const [isTimeboxerManuallyOpened, setIsTimeboxerManuallyOpened] =
    useState(true);

  return (
    <>
      {
        // isTimeboxerOpen && isTimeboxListOpen
        true && (
          <>
            {/* <TimeboxList selectedUser={selectedUser} /> */}
            <div
              id="reportBuilderPageList"
              className={
                isTimeboxerManuallyOpened
                  ? isTimeboxerOpen && isTimeboxListOpen
                    ? 'isReportBuilderPageListOpenTrue'
                    : 'isReportBuilderPageListOpenFalse'
                  : 'reportBuilderPageListDefault'
              }
              onContextMenu={(e) => e.preventDefault()}
              ref={reportBuilderListRef}
              style={{
                transform: `scale(${reportBuilderListScale})`,
                transformOrigin: 'right',
                position: 'absolute',
                // right:
                //   (numDays < 4 ? numDays : 4) * 300 -
                //   (10 - 10 * reportBuilderListScale) * 15 +
                //   'px',
                right: right + 'px',
                // top: '0px',
                border:
                  theme?.palette?.mode === 'dark' &&
                  '1px solid rgb(32 32 41 / 60%)', //'1px solid rgba(255,255,255,0.05)',
                width: '200px',
                overflow: 'hidden',
                height: window.innerHeight - 50 + 'px',
                // height: '100%',
                // background:
                //   theme?.palette?.mode === 'dark'
                //     ? 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
                //     : 'rgba(255,255,255,0.3)',
                // background: 'red',
                backdropFilter: 'blur(10px)',
                // background: 'red',
                position: 'absolute',
                // right: '0px',
                // top: '-700px',
                // top: '0px',
                zIndex: '15000',
                borderRadius: '5px',
                // padding: '10px',
                boxShadow:
                  theme?.palette?.mode === 'dark'
                    ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' // '0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                    : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                // boxShadow:
                //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
              }}
              // onMouseMove={updateMousePosition}
              // //   onMouseDown={onMouseDownHandler}
              // onMouseDown={(e) => {
              //   // e.preventDefault();
              //   e.stopPropagation();
              //   onMouseDownHandler(e, null, 'create');
              // }}
              // onMouseUp={(e) => {
              //   e.stopPropagation();
              //   // e.preventDefault();
              //   onMouseUpHandler(e, 'timeboxer', null);
              // }}
            >
              <div
                style={{
                  background:
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(28 28 41 / 40%)' // 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
                      : 'rgba(255,255,255,0.4)',
                  height: '50px',
                  width: '100%',
                  padding: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  // flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: 12 / reportBuilderListScale + 'px'
                }}
              >
                {/* <div
                  style={{
                    width: 54 / reportBuilderListScale + 'px'
                    // position: 'absolute'
                  }}
                ></div> */}
                {/* <div>
                  <TimeboxListSelectPopover
                  // selectedTimeboxList={selectedTimeboxList}
                  // setSelectedTimeboxList={setSelectedTimeboxList}
                  />
                </div> */}
                {/* <SaveTemplatePopper /> */}

                <div
                  style={{ width: 60 / reportBuilderListScale + 'px' }}
                ></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    onClick={() => {
                      if (reportBuilderListScale >= 0.5) {
                        setReportBuilderListScale(reportBuilderListScale - 0.1);
                      }
                    }}
                    style={{
                      minWidth: 18 / reportBuilderListScale + 'px',
                      width: 18 / reportBuilderListScale + 'px',
                      minHeight: 18 / reportBuilderListScale + 'px',
                      height: 18 / reportBuilderListScale + 'px'
                    }}
                  >
                    <ZoomInMapIcon
                      style={{ fontSize: 14 / reportBuilderListScale + 'px' }}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      if (reportBuilderListScale <= 0.9) {
                        setReportBuilderListScale(reportBuilderListScale + 0.1);
                      }
                    }}
                    style={{
                      minWidth: 18 / reportBuilderListScale + 'px',
                      width: 18 / reportBuilderListScale + 'px',
                      minHeight: 18 / reportBuilderListScale + 'px',
                      height: 18 / reportBuilderListScale + 'px'
                    }}
                  >
                    <ZoomOutMapIcon
                      style={{ fontSize: 14 / reportBuilderListScale + 'px' }}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      setIsTimeboxListOpen(!isTimeboxListOpen);
                    }}
                    style={{
                      minWidth: 18 / reportBuilderListScale + 'px',
                      width: 18 / reportBuilderListScale + 'px',
                      minHeight: 18 / reportBuilderListScale + 'px',
                      height: 18 / reportBuilderListScale + 'px'
                    }}
                  >
                    <CloseIcon
                      style={{ fontSize: 14 / reportBuilderListScale + 'px' }}
                    />
                  </Button>
                </div>
              </div>
              <div
                onMouseLeave={(e) => {}}
                ref={ref}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '200px',
                  height: '100%',
                  //   maxHeight: '80%',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  position: 'relative',
                  background:
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                      : 'rgba(255,255,255,0.3)'
                }}
              >
                <div
                  // onDrop={onDropHandler}
                  // onDragOver={onDragOverHandler}
                  style={{
                    position: 'relative',
                    // height: 24 * 200 + 'px',
                    // background: 'blue',
                    // height: 24 * 60 * zoom + 'px',
                    height:
                      (window.innerHeight - 100 >
                      (report?.pages?.length || 0) * 100
                        ? window.innerHeight - 100
                        : report?.pages?.length * 100) + 'px',
                    // display: 'flex',
                    // width: 'calc(100%-10px)'
                    // height: '1200px',
                    width: '270px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    alignContent: 'center',
                    paddingLeft: '7px',
                    paddingTop: '15px',
                    backgroundSize: `${30 * zoom + 'px'} ${30 * zoom + 'px'}`,
                    backgroundPosition: 'center 0px',

                    backgroundImage:
                      'linear-gradient(to bottom, rgba(120, 120, 120, 0.15) 1px, rgba(229, 229, 247, 0) 1px)'
                  }}
                >
                  {(report?.pages || []).map((reportPage) => {
                    return (
                      <ReportBuilderPage
                        reportPage={reportPage}
                        // setIsDragging={setIsDragging}
                        // reportBuilderListScale={reportBuilderListScale}
                        // onContextHandler={onContextHandler}
                        // selectedUser={selectedUser}
                        // isMouseDown={isMouseDown}
                        // setIsMouseDown={setIsMouseDown}
                        // setIsTimeboxesMoved={setIsTimeboxesMoved}
                        // zoom={zoom}
                        // isShiftSelected={isShiftSelected}
                        // isTimeboxesMoved={isTimeboxesMoved}
                        // mouseDownTimeboxIds={mouseDownTimeboxIds}
                        // // setOriginalTimeboxes={setOriginalTimeboxes}
                        // timebox={timebox}
                        // mousePosition={mousePosition}
                        // onMouseDownHandler={onMouseDownHandler}
                        // onMouseUpHandler={onMouseUpHandler}
                      />
                    );
                  })}
                </div>
              </div>
              <ReportPageListContextMenu
              // mouseDownTimeboxIds={mouseDownTimeboxIds}
              // contextMenuAnchorPosition={contextMenuAnchorPosition}
              // setContextMenuAnchorPosition={setContextMenuAnchorPosition}
              // timeboxIdThatTriggeredContextMenu={
              //   timeboxIdThatTriggeredContextMenu
              // }
              // setTimeboxIdThatTriggeredContextMenu={
              //   setTimeboxIdThatTriggeredContextMenu
              // }
              // timeboxes={timeboxes}
              // selectedUser={selectedUser}
              />
              {/* <ReportBuilderContextMenu
                mouseDownTimeboxIds={mouseDownTimeboxIds}
                contextMenuAnchorPosition={contextMenuAnchorPosition}
                setContextMenuAnchorPosition={setContextMenuAnchorPosition}
                widgetIddThatTriggeredContextMenu={
                  widgetIdThatTriggeredContextMenu
                }
                setWidgetIdThatTriggeredContextMenu={
                  setWidgetIdThatTriggeredContextMenu
                }
                timeboxes={timeboxes}
                selectedUser={selectedUser}
              /> */}
              {/* <TimeboxerViewDialog
              open={isTimeboxViewDialogOpen}
              setOpen={setIsTimeboxViewDialogOpen}
              selectedDateStartTimebox={selectedDateStartTimebox}
              selectedDateStopTimebox={selectedDateStopTimebox}
            /> */}
            </div>
          </>
        )
      }
    </>
  );
}

export default ReportBuilderPageList;
