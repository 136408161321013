import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import DragDropFile from './DragAndDropInput';

import { DialogContext } from '../../../../../context/DialogContext';

import { AccountContext } from '../../../../../context/AccountContext';

import { SnackbarProvider, useSnackbar } from 'notistack';

import SpinnerSmall from '../../../../layout/SpinnerSmall';

import ReportAsset from './report-asset/ReportAsset';

import SelectAccountMenu from '../../utils/SelectAccountMenu';

import Checkbox from '@mui/material/Checkbox';

import { useMutation } from '@apollo/client';

import { uploadReportAssetsMutation } from './../../../../../graphql/queries';

import { createThumbnail } from './../../report-builder-list/createThumbnail';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

// import { MutationContext } from '../../../context/MutationContext';

// import {
//   useQuery,
//   useLazyQuery,
//   useMutation,
//   useSubscription
// } from '@apollo/client';
// import { addDocumentMutation } from './../../../graphql/queries';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog() {
  const { enqueueSnackbar } = useSnackbar();

  const { refetchAssets, setRefetchAssets } = useContext(ReportBuilderContext);
  const { currentManagerAccountId } = useContext(AccountContext);
  const {
    uploadReportAssetsDialogOpen: open,
    setUploadReportAssetsDialogOpen: setOpen
  } = useContext(DialogContext);

  const [isAllowingManagerAccountAccess, setIsAllowingManagerAccountAccess] =
    useState(false);

  const [isAllowingAccountAccess, setIsAllowingAccountAccess] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [fileUploaded, toggleFileUploaded] = useState(false);

  const [reportAssets, setReportAssets] = useState([]);

  const [uploadReportAssets, { called }] = useMutation(
    uploadReportAssetsMutation
  );
  // console.log('excelData1: ', excelData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  console.log('selectedAccountIds: ', selectedAccountIds);

  const handleClose = () => {
    // setNewNexusDocuments([]);
    // setExcelData({});
    setIsLoading(false);
    toggleFileUploaded(false);
    setOpen(false);
  };

  const handleUpload = async () => {
    if (
      !isAllowingManagerAccountAccess &&
      (!isAllowingAccountAccess || !selectedAccountIds?.length)
    ) {
      enqueueSnackbar('Please choose access', {
        variant: 'error'
      });
      return;
    }

    setIsLoading(true);

    let input = {
      managerAccountId: currentManagerAccountId,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      reportAssets
    };

    const res = await uploadReportAssets({
      variables: { input }
    });

    if (res?.data?.uploadReportAssets?.response === 'success') {
      enqueueSnackbar('Assets uploeaded', {
        variant: 'success'
      });

      setRefetchAssets(refetchAssets + 1);
      // setAnchorEl(null);
    }
    setIsLoading(false);
    handleClose();
  };

  const headerText = 'Upload image assets';
  console.log('reportAssets1: ', reportAssets);
  const [rerender, setRerender] = useState(0);

  let reportAssets_ = [];
  reportAssets_ = (reportAssets || [])?.map((reportAsset, index) => (
    <ReportAsset
      reportAsset={reportAsset}
      reportAssets={reportAssets}
      setReportAssets={setReportAssets}
      reportAssetIndex={index}
    />
  ));

  // useEffect(() => {
  //   reportAssets_ = (reportAssets || [])?.map((reportAsset) => (
  //     <ReportAsset reportAsset={reportAsset} />
  //   ));
  //   // const runScript = async () => {
  //   //   if (reportAssets?.length) {
  //   //     reportAssets_ = (reportAssets || [])?.map((reportAsset) => (
  //   //       <ReportAsset reportAsset={reportAsset} />
  //   //     ));
  //   //     console.log('reportAssets111: ', reportAssets);
  //   //   } else {
  //   //     await setTimeout(() => {
  //   //       console.log('Delayed for 1 second.');
  //   //     }, '2000');
  //   //     setRerender(rerender + 1);
  //   //   }
  //   // };

  //   const runScript = async () => {
  //     if (!reportAssets[0]?.type) {
  //       await setTimeout(() => {
  //         console.log('Delayed for 1 second.');
  //       }, '2000');
  //       setRerender(rerender + 1);
  //     }
  //   };

  //   runScript();
  // }, [reportAssets, reportAssets?.[0]?.type, rerender]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="80%"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              // display: 'grid',
              // gridTemplateColumns: 'auto 40px 40px 30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1
              }}
            >
              <span style={{ paddingLeft: '7px' }}>
                <UploadFileIcon
                  style={{
                    verticalAlign: 'text-bottom',
                    paddingRight: '5px'
                  }}
                />{' '}
                {headerText}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '65px'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span
                    style={{ width: '50px' }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? (
                      <FullscreenExitIcon className="hover-light-grey" />
                    ) : (
                      <FullscreenIcon className="hover-light-grey" />
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span style={{ width: '50px' }} onClick={handleClose}>
                    <CloseIcon className="hover-light-grey" />
                  </span>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
          </div>
        </DialogTitle>
        <DialogContent>
          {fileUploaded ? (
            isLoading ? (
              <div
                style={{
                  width: '400px',
                  height: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <SpinnerSmall />
              </div>
            ) : (
              // <DataEditor
              //   excelData={excelData}
              //   setExcelData={setExcelData}
              //   newNexusDocuments={newNexusDocuments}
              //   setNewNexusDocuments={setNewNexusDocuments}
              // />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start'
                }}
              >
                <div style={{ display: 'relative', width: '500px' }}>
                  {reportAssets_}
                </div>
                <div style={{ width: '400px' }}>
                  <div>Access:</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Checkbox
                        size="small"
                        checked={isAllowingManagerAccountAccess}
                        onChange={() =>
                          setIsAllowingManagerAccountAccess(
                            !isAllowingManagerAccountAccess
                          )
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div>Current Manager Account</div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Checkbox
                        size="small"
                        checked={isAllowingAccountAccess}
                        onChange={() =>
                          setIsAllowingAccountAccess(!isAllowingAccountAccess)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div>
                      <SelectAccountMenu
                        isDisabled={!isAllowingAccountAccess}
                        selectedAccountIds={selectedAccountIds}
                        setSelectedAccountIds={setSelectedAccountIds}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <DragDropFile
              reportAssets={reportAssets}
              setReportAssets={setReportAssets}
              toggleFileUploaded={toggleFileUploaded}
            />
          )}

          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleUpload}>Upload</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
