import React, { useState, useEffect } from 'react';

import './style.css';

import readXlsxFile from 'read-excel-file';
import { readSheetNames } from 'read-excel-file';

import { SnackbarProvider, useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import Resizer from 'react-image-file-resizer';

// drag drop file component
const resizeFile = (
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth, // Is the maxWidth of the resized new image.
      maxHeight, // Is the maxHeight of the resized new image.
      compressFormat, // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image.
      rotation, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri);
      }, // Is the callBack function of the resized new image URI.
      outputType, // Is the output type of the resized new image.
      minWidth, // Is the minWidth of the resized new image.
      minHeight // Is the minHeight of the resized new image.
    );
  });

function DragDropFile({ reportAssets, setReportAssets, toggleFileUploaded }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  const [newAssets, setNewAssets] = useState([]);

  const parseFiles = async (files) => {
    const newAssetObjects = [];
    //   var files = e.target.files,

    for (let file of files) {
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        console.log('file type not supported');

        enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
          variant: 'error'
        });

        return;
      }
    }
    for (let file of files) {
      const thumbnailImage = await resizeFile(
        file,
        300,
        300,
        'PNG',
        20,
        0,
        () => {},
        'base64'
      );

      const resizedFile = await resizeFile(
        file,
        1920,
        1920,
        file.type, //'PNG',
        100,
        0,
        () => {},
        'file'
      );

      if (file?.size > 3000000) {
        console.log('file.size too big>file.size: ', file.size);
        enqueueSnackbar('FILE TOO HEAVY. MAX WEIGHT 3mb.', {
          variant: 'error'
        });
        return;
      }
      // console.log('debug2543>image:', image);

      // console.log('debug7456>file: ', file);

      const newAssetObject = {};

      const img = new Image();
      img.src = URL.createObjectURL(resizedFile);
      // img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        // setImgNaturalHeight(this.naturalHeight);
        // setImgNaturalWidth(this.naturalWidth);

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        console.log('debug7456>dataURL: ', dataUri);

        newAssetObject.imageDataUri = dataUri;
        newAssetObject.imageOriginalAspectRatio =
          img.naturalWidth / img.naturalHeight;
        newAssetObject.naturalHeight = img.naturalHeight;
        newAssetObject.naturalWidth = img.naturalWidth;

        newAssetObject.lastModified = String(file.lastModified);
        newAssetObject.lastModifiedDate = file.lastModifiedDate;
        newAssetObject.name = file.name;
        newAssetObject.fileName = file.name;
        newAssetObject.size = file.size;
        newAssetObject.type = file.type;

        newAssetObject.id = uuidv4();
        newAssetObject.thumbnailImageDataUri = thumbnailImage;
        // await setTimeout(() => {
        //   setNewAssets([...newAssets, newAssetObject]);
        // }, '2000');
        // await setNewAssets([...newAssets, newAssetObject]);
        // console.log('debug7456>newAssetObject: ', newAssetObject);
        newAssetObjects.push(newAssetObject);
      };

      // const files = e.dataTransfer.files;
      // console.log('files: ', files);
      // return;

      // for (let file of files) {
      let reader = new FileReader();

      reader.onload = async () => {
        // console.log('reader.result: ', reader.result);
        const binaryStr = reader.result;

        // setImageUrl(binaryStr);
        // updateWidget(copyOfWidget, widgetIndex);

        // setDragActive(false);

        console.log('debug7456>binaryStr: ', binaryStr);
        setReportAssets(newAssetObjects);
      };

      await reader.readAsDataURL(resizedFile);
      // await reader.readAsDataURL(file);
      // newAssetObjects.push(newAssetObject);

      // setReportAssets([...(reportAssets || []), newAssetObject]);
    }
    // var f = files[0];
    // var sheets = {};

    console.log('debug7456>newAssetObjects:', newAssetObjects);

    console.log('after delay');
    // const newAssetObjects_ = await JSON.parse(JSON.stringify(newAssetObjects));
    // // console.log('newAssetObjects_: ', newAssetObjects_);
    // await setReportAssets(newAssetObjects);

    // setExcelData(sheets);
    await toggleFileUploaded(true);
  };

  console.log('newAssets: ', newAssets);

  useEffect(() => {
    setReportAssets(newAssets);
  }, [newAssets]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = e.dataTransfer.files;
      parseFiles(files);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;
      // handleFiles(e.target.files);

      parseFiles(files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        style={{ cursor: 'pointer', background: 'rgba(150,150,150,0.3)' }}
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? 'drag-active' : ''}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>
            Upload a file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
export default DragDropFile;
