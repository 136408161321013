import React, { useState, useEffect, useContext } from 'react';

import DataSourceHealthDashboardTable from './DataSourceHealthDashboardTable';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';

import { dataSourceErrorsQuery } from './../../graphql/queries';

import { useLazyQuery } from '@apollo/client';

import { AccountContext } from '../../context/AccountContext';

function DataSourceHealthDashboard({ stylesStoredInRedux }) {
  const { currentManagerAccount, currentAccountIds } =
    useContext(AccountContext);

  const [warnings, setWarnings] = useState([]);

  const [skip, setSkip] = useState(true);

  const [arrayOfData, setArrayOfData] = useState([]);

  // console.log('Warning>currentAccountIds: ', currentAccountIds);

  const dataSourceDict = {
    GoogleAdsDataSource: 'Google Ads',
    FacebookAdsDataSource: 'Facebook Ads',
    GoogleAnalytics4DataSource: 'Google Analytics 4',
    CM360DataSource: 'Campaign Manager 360',
    DV360DataSource: 'Display & Video 360',
    SnapchatAdsDataSource: 'Snapchat Ads',
    TikTokAdsDataSource: 'TikTok Ads',
    PinterestAdsDataSource: 'Pinterest Ads',
    ReadpeakDataSource: 'Readpeak',
    StrossleDataSource: 'Strossle',
    AmazonDspDataSource: 'Amazon DSP',
    TaboolaAdsDataSource: 'Taboola Ads',
    MicrosoftAdvertisingDataSource: 'Microsoft Advertising',
    MicrosoftInvestDataSource: 'Microsoft Invest',
    AppleSearchAdsDataSource: 'Apple Search Ads',
    LinkedInAdsDataSource: 'LinkedIn Ads',
    AdformDataSource: 'Adform'
  };

  const currentManagerAccountId = currentManagerAccount?._id;

  // console.log('currentManagerAccountId: ', currentManagerAccountId);
  const variables = {
    dataSourceErrorsInput: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      includeTokenInfo: true,
      showAll: true
    }
  };

  // const { data, refetch } = useQuery(dataSourceErrorsQuery, {
  //   variables,
  //   fetchPolicy: 'no-cache',
  //   skip: !currentManagerAccountId && skip
  // });

  const [loadWarnings, { called, loading, data }] = useLazyQuery(
    dataSourceErrorsQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    loadWarnings();
  }, []);
  console.log('arrayOfData: ', arrayOfData);

  useEffect(() => {
    if (data?.dataSourceErrors?.length) {
      function compare(a, b) {
        if (a.dataSourceUpdatedAt < b.dataSourceUpdatedAt) {
          return 1;
        }
        if (a.dataSourceUpdatedAt > b.dataSourceUpdatedAt) {
          return -1;
        }
        return 0;
      }

      const sortedData = data?.dataSourceErrors.sort(compare);

      const parsedData = sortedData?.map((obj) => ({
        _id: obj._id,
        level: 1,
        type: dataSourceDict[obj.dataSourceRef],
        dataSourceRef: obj.dataSourceRef,
        dataSourceUpdatedAt:
          obj.dataSourceUpdatedAt &&
          new Date(parseInt(obj.dataSourceUpdatedAt)).toISOString(),
        name: obj.name,
        // 'warnings',
        status: obj.status,
        dataSourceId: obj._id,
        dataSourceAccountId: obj.dataSourceAccountId,
        dataSourceErrorCode: obj.dataSourceErrorCode,
        dataSourceErrorMessage: obj.dataSourceErrorMessage,
        tokenId: obj.tokenId,
        tokenUser: obj.tokenInfo?.user?.email,
        tokenExpiresAt:
          obj?.tokenInfo?.expiresAt &&
          new Date(parseInt(obj?.tokenInfo?.expiresAt)).toDateString(),
        tokenErrorCode: obj.tokenErrorCode,
        tokenErrorMessage: obj.tokenErrorMessage,
        lastDataFetchLongAt: obj.lastDataFetchLongAt,
        lastDataFetchShortAt: obj.lastDataFetchShortAt
      }));
      // console.log('parsedData: ', parsedData);

      //      setArrayOfData(parsedData?.filter((obj) => obj.status !== 'DEACTIVATED'));
      setArrayOfData(parsedData);
    }
  }, [data]);

  console.log('DataSourceHealthDashboard>data: ', data);

  // const stylesStoredInRedux = {};

  // const [loading, toggleLoading] = useState(false);
  return (
    <div
      style={{
        padding: '0 15px 0 15px'
      }}
    >
      <div
        // className="underlined"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',

          borderRadius: '5px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 6,
              gridColumnEnd: 6,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '1rem 0px',
              minHeight: '68px'
            }}
          >
            {/* <FilterMenu
              setFilterBySelection={setFilterBySelection}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              setFilters={setFilters}
              filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            /> */}
          </div>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gridColumnStart: 7,
              gridColumnEnd: 7,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '0.91rem',
              justifyContent: 'left'
            }}
          >
            <FilterComponent
              setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // setFilterBySelection={setFilterBySelection}
              // setFilterByDataSource={setFilterByDataSource}
              // filterByDataSource={filterByDataSource}
              names={names}
              dataSourceNames={dataSourceNames}
            />
          </div> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* {advancedMode && (
            <div
              style={{
                display: 'grid',
                //   border: '1px solid rgba(200, 200, 200, 0.5)',
                //   height: '53px',
                borderRadius: '5px',
                // width: '200px',
                gridColumnStart: 8,
                gridColumnEnd: 8,

                textAlign: 'right',
                // padding: '1.4rem 1rem 1rem 1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                justifyContent: 'center'
                // backgroundColor: 'blue'
              }}
            >
              <DataLevelSwitch
                dataLevel={dataLevel}
                setDataLevel={setDataLevel}
                currentAccountIds={currentAccountIds}
              />
            </div>
          )} */}

          {/* <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 12,
              gridColumnEnd: 12,

              textAlign: 'right',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            <ColumnMenu
              currentManagerAccountId={currentManagerAccount._id}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              setOpenSimpleModal={setOpenSimpleModal}
              user={user}
            />
            {columnDialogOpen && <ColumnDialog />}
          </div> */}
        </div>
      </div>

      {/* uncomment */}

      <DataSourceHealthDashboardTable
        stylesStoredInRedux={stylesStoredInRedux}
        // openRows={openRows}
        // setOpenRows={setOpenRows}

        arrayOfData={arrayOfData}
        setArrayOfData={setArrayOfData}
      />
      {/* <AlertDialog /> */}
      {loading && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            // backgroundColor: 'red',
            height: '100px',
            width: '100px',
            zIndex: 3000,
            bottom: 0,
            right: '30px'
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}

// export default DataSourceHealthDashboard;

DataSourceHealthDashboard.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(DataSourceHealthDashboard);
