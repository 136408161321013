import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportPagesQuery } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

import ReportPage from './ReportPage';

function ReportPages({
  selectedReport,
  setSelectedReport,
  selectedReportPage,
  setSelectedReportPage
}) {
  const theme = useTheme();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const [reportPages, setReportPages] = useState();
  // const [selectedReportPage, setSelectedReportPage] = useState();
  console.log('selectedReport: ', selectedReport);

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      reportId: selectedReport?._id,
      reportType: selectedReport?.type
    }
  };

  const [loadReportPages, { called, loading, data }] = useLazyQuery(
    reportPagesQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (currentManagerAccountId && currentAccountIds && selectedReport) {
      loadReportPages();
    }
  }, [currentManagerAccountId, currentAccountIds, selectedReport]);

  useEffect(() => {
    if (data?.reportPages && called) {
      setReportPages(data?.reportPages);
    }
  }, [data]);
  console.log('reportPages: ', reportPages);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        // background: 'red',
        // display: 'flex',
        // flexDirection: 'row',
        // margin: '0px 10px',
        padding: '10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(0,0,0,0.1)'
            : 'rgba(255,255,255,0.3)',
        overflowY: 'scroll'
      }}
    >
      {(reportPages || []).map((reportPage) => (
        <ReportPage
          reportPage={reportPage}
          selectedReportPage={selectedReportPage}
          setSelectedReportPage={setSelectedReportPage}
        />
      ))}
    </div>
  );
}

export default ReportPages;
