import React, { useEffect, useContext } from 'react';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';
import { useTheme } from '@emotion/react';

function FractionBarItem({
  numerator,
  denominator,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  textAlignRight,
  checked
}) {
  const { exportMode, advancedMode } = useContext(InterfaceContext);

  const theme = useTheme();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  // if (textAlignRight) {
  //   spanStyle = { textAlign: itemValue === '?' ? 'center' : 'right' };
  // } else {
  //   spanStyle = { textAlign: itemValue === '?' ? 'center' : 'center' };
  // }

  // let renderValue = '';
  // if (itemValue === '?') {
  //   renderValue = <OutOfDateRangeTooltip />;
  // } else if (isNaN(itemValue) || itemValue === undefined || !itemValue) {
  //   renderValue = '';
  // } else if (!isNaN(itemValue)) {
  //   renderValue = itemValue && parseInt(itemValue).toLocaleString();
  // }

  let displayValue = '';
  let background = '';
  let width = '';
  let justifyContent = '';
  let showBar = false;

  let isOverSpend = false;

  switch (itemKey) {
    case 'daysLeft':
      displayValue = `${numerator}d`;
      background =
        theme.palette.mode === 'dark'
          ? `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.05)) rgba(255, 255, 255, 0.05)`
          : `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.5)) rgba(255, 255, 255, 0.5)`;
      width = denominator
        ? 100 - (numerator * 100 || 0) / denominator + '%'
        : 0;
      justifyContent = 'start';
      showBar = numerator && denominator;
      break;
    case 'budgetSpent':
    case 'timeSpent':
      // displayValue = `${Math.round(((numerator || 0) / denominator) * 100)} %`;
      displayValue = item[itemKey] + ' %';

      background =
        theme.palette.mode === 'dark'
          ? `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.05)) rgba(255, 255, 255, 0.05)`
          : `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.5)) rgba(255, 255, 255, 0.5)`;
      // width = denominator
      //   ? Math.round(100 - ((numerator || 0) * 100 || 0) / denominator)
      //   : 0;

      width = 100 - item[itemKey];
      if (width < 0) {
        width = 0;
        background = 'red';
        isOverSpend = true;
      }
      width = width + '%';

      justifyContent = 'end';
      showBar = denominator;

      break;
  }

  if (item?.isSetChild) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          // display: 'grid',
          height: '100%',
          width: '100%',
          // padding: '12px 15px',
          whiteSpace: 'nowrap',
          cursor: 'default'
        }}
      ></div>
    );
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        // display: 'grid',
        height: '100%',
        width: '100%',
        // padding: '12px 15px',
        whiteSpace: 'nowrap',
        cursor: 'default'
      }}
    >
      {(inView || exportMode) && showBar ? (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: ' row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          {/* <p>{isNaN(renderValue) ? '-' : renderValue}</p> */}
          <div
            style={{
              width: '80%',
              height: '50%',
              borderRadius: '5px',
              backgroundColor: 'white',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent, // 'start',
              // justifyContent: 'end',
              alignItems: 'center',
              overflow: 'hidden',
              background,
              // theme.palette.mode === 'dark'
              //   ? `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.05)) rgba(255, 255, 255, 0.05)`
              //   : `linear-gradient(to left top, ${item?.statusColor}, rgba(255, 255, 255, 0.5)) rgba(255, 255, 255, 0.5)`,
              border:
                '1px solid ' +
                (isOverSpend
                  ? 'red'
                  : theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,0.2)'
                  : item?.statusColor || 'green')
            }}
          >
            <div
              style={{
                // position: 'relative',
                height: '100%',
                // width: (numerator * 100 || 0) / denominator - 1 + '%',
                width, //100 - (numerator * 100 || 0) / denominator + '%',
                background: itemStyle['backgroundColor'] //'white'
              }}
            ></div>
          </div>
          <div
            style={{
              width: '80%',
              height: '50%',
              borderRadius: '5px',
              position: 'absolute',
              // background: 'red',
              background:
                theme.palette.mode === 'dark'
                  ? undefined
                  : 'rgba(255,255,255,0.5)'
              // background:
              //   'linear-gradient(to left top, rgba(255,255,255,0), rgba(255, 255, 255, 0.5)) rgba(255, 255, 255, 0.5)'
            }}
          ></div>
          <span style={{ position: 'absolute' }}>
            {/* {denominator && `${numerator} / ${denominator}`} */}
            {displayValue}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default FractionBarItem;
