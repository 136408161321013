import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { v4 as uuidv4 } from 'uuid';

import { updateDocumentMutation } from './../graphql/queries';

import { FilterContext } from './FilterContext';

export const DataContext = React.createContext();

export const DataProvider = (props) => {
  const {
    checkedBoxes,
    setCheckedBoxes
    //  setCheckedBoxesIds, checkedBoxesIds
  } = useContext(FilterContext);
  // const clientId = uuidv4();

  // const [subscriptionCallStack, setSubscriptionCallStack] = useState([]);
  const [listOfData, setListOfData] = useState([]);
  const [arrayOfData, setArrayOfData] = useState([]);

  const [arrayOfDataSourceData, setArrayOfDataSourceData] = useState([]);

  const [listOfFilteredDataSourceRows, setListOfFilteredDataSourceRows] =
    useState([]);

  const [report, updateReport] = useState({
    pages: [
      { id: uuidv4(), width: '1920px', height: '1080px', widgets: [] }
      // { _id: uuidv4(), pageWidth: '', pageHeight: '', widgets: [] }
    ]
  });

  const setReport = (update) => {
    // console.log('debug2465>DataContext>setReport');
    updateReport(update);
  };

  // const [updateDocument_] = useMutation(updateDocumentMutation);

  // const updateDocument = async (parameterObject) => {
  //   try {
  //     const newParameterObject = {
  //       ...parameterObject,
  //       variables: { ...parameterObject.variables, clientId }
  //     };
  //     const res = await updateDocument_(newParameterObject);
  //     setSubscriptionCallStack([
  //       ...subscriptionCallStack,
  //       {
  //         subscriptionType: 'documentUpdatedSubscription',
  //         subscriptionData: res.data.updateDocument.documentsUpdated
  //       }
  //     ]);
  //     console.log('DataContext>res: ', res);
  //     return res;
  //   } catch (error) {
  //     console.log('DataContext>error: ', error);
  //   }
  // };

  useEffect(() => {
    setCheckedBoxes(
      listOfData.filter((obj) =>
        checkedBoxes.map((doc) => doc._id).includes(obj._id)
      )
    );
  }, [listOfData]);

  const [isLoading, setIsLoading] = useState(true);

  const [timeboxes, setTimeboxes] = useState([]);
  const [timeboxListTimeboxes, setTimeboxListTimeboxes] = useState([]);

  const [selectedTimeboxList, setSelectedTimeboxList] = useState();
  const [userTimeboxLists, setUserTimeboxLists] = useState();

  useEffect(() => {
    let listOfIdsToFilter = [];
    for (let row of arrayOfDataSourceData || []) {
      if (!listOfIdsToFilter.includes(row._id)) {
        listOfIdsToFilter.push(row._id);
      }
      for (let id of row?.allChildDocumentIds || []) {
        if (!listOfIdsToFilter.includes(id)) {
          listOfIdsToFilter.push(id);
        }
      }
    }

    let dataSourceRows = listOfData.filter((row) =>
      [
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'manualDataEntry'
      ].includes(row.type)
    );

    dataSourceRows = dataSourceRows.filter((row) =>
      listOfIdsToFilter.includes(row._id)
    );

    setListOfFilteredDataSourceRows(dataSourceRows);
  }, [listOfData, arrayOfDataSourceData]);

  return (
    <DataContext.Provider
      value={{
        listOfData,
        setListOfData,
        arrayOfData,
        setArrayOfData,
        arrayOfDataSourceData,
        setArrayOfDataSourceData,
        isLoading,
        setIsLoading,
        timeboxes,
        setTimeboxes,
        timeboxListTimeboxes,
        setTimeboxListTimeboxes,
        selectedTimeboxList,
        setSelectedTimeboxList,
        userTimeboxLists,
        setUserTimeboxLists,
        listOfFilteredDataSourceRows,
        setListOfFilteredDataSourceRows,
        report,
        setReport

        // subscriptionCallStack,
        // setSubscriptionCallStack,
        // updateDocument
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
