import React, { useContext, useState, useEffect } from 'react';

import LeftAndRightAxis from './LeftAndRightAxis';

import DropHere from './DropHere';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

function AxisMenu({ widget }) {
  const {
    currentlyDraggingWidget,
    setCurrentlyDraggingWidget,
    isAxisMenuOpen,
    toggleIsAxisSettingsOpen
  } = useContext(ReportBuilderContext);

  switch (true) {
    case ['BAR_CHART_WIDGET'].includes(widget.type) &&
      ['metrics', 'dimensions'].includes(currentlyDraggingWidget?.category):
    case ['BAR_CHART_WIDGET'].includes(widget.type) &&
      isAxisMenuOpen(widget.id):
    case ['BAR_CHART_WIDGET'].includes(widget.type) &&
      isAxisMenuOpen(widget.id):
      return <LeftAndRightAxis widget={widget} />;
      break;

    case ['metrics'].includes(currentlyDraggingWidget?.category) &&
      [
        'BAR_CHART_WIDGET',
        'LINE_CHART_WIDGET',
        'PIE_CHART_WIDGET',
        'NUMBER_WIDGET'
      ].includes(widget.type):
    case ['intervals'].includes(currentlyDraggingWidget?.category) &&
      [
        'BAR_CHART_WIDGET',
        'LINE_CHART_WIDGET'
        // 'PIE_CHART_WIDGET',
        // 'NUMBER_WIDGET'
      ].includes(widget.type):
      return <DropHere widget={widget} />;
  }
  return <></>;
}

export default AxisMenu;
