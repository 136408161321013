import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import formatDateYYYYMMDD from './../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';
import { AccountContext } from './AccountContext';

import { InterfaceContext } from './InterfaceContext';

import { DialogContext } from './DialogContext';

import { createThumbnail } from '../components/tableV4/report-builder-2/report-builder-list/createThumbnail';

import { calcWidgets } from './report-builder-functions/calcWidgets';
import { calcWidget } from './report-builder-functions/calcWidget';

export const ReportBuilderContext = React.createContext();

// #################### imports regarding date range #################################

export const ReportBuilderProvider = (props) => {
  const { managerAccounts, setManagerAccounts } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const { report, setReport, listOfFilteredDataSourceRows } =
    useContext(DataContext);

  const [currentlyDraggingWidget, setCurrentlyDraggingWidget] = useState(null);
  const [widgetIdsWithAxisSettingsOpen, setWidgetIdsWithAxisSettingsOpen] =
    useState([]);
  const toggleIsAxisSettingsOpen = (widgetId) => {
    const copyOfwidgetIdsWithAxisSettingsOpen = [
      ...widgetIdsWithAxisSettingsOpen
    ];
    if (widgetIdsWithAxisSettingsOpen.includes(widgetId)) {
      copyOfwidgetIdsWithAxisSettingsOpen.splice(
        widgetIdsWithAxisSettingsOpen.indexOf(widgetId),
        1
      );

      setWidgetIdsWithAxisSettingsOpen(copyOfwidgetIdsWithAxisSettingsOpen);
    } else {
      setWidgetIdsWithAxisSettingsOpen([
        ...widgetIdsWithAxisSettingsOpen,
        widgetId
      ]);
    }
  };

  const isAxisMenuOpen = (widgetId) => {
    return widgetIdsWithAxisSettingsOpen.includes(widgetId);
  };

  const { aggregatedDateRange, startDateForLastDaysData } =
    useContext(InterfaceContext);

  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState();

  const { isReportBuilderOpen } = useContext(DialogContext);

  const [
    reportPageListContextMenuAnchorPosition,
    setReportPageListContextMenuAnchorPosition
  ] = useState();
  const [
    reportPageIdThatTriggeredContextMenu,
    setReportPageIdThatTriggeredContextMenu
  ] = useState();
  // const [widgets, setWidgets] = useState([]);

  const [selectedPageId, updateSelectedPageId] = useState(null);

  // const [reportPage, setReportPage] = useState();

  useEffect(() => {
    if (selectedPageId) {
      const { pageIndex: previousPageIndex, page: previousPage } =
        getPage(selectedPageId);
      if (previousPageIndex < 0) {
        setSelectedPageId(report?.pages?.[0]?.id);
        // setReportPage(report?.pages?.[0]);
      }
    } else {
      setSelectedPageId(report?.pages?.[0]?.id);
    }
  }, [report?.pages]);

  // useEffect(() => {
  //   if (selectedPageId) {
  //     const { pageIndex: previousPageIndex, page: previousPage } =
  //       getPage(selectedPageId);
  //     if (previousPageIndex < 0) {
  //       // setSelectedPageId(report?.pages?.[0]?.id);
  //       setReportPage(report?.pages?.[0]);
  //     } else {
  //       setReportPage(previousPage);
  //     }
  //   }
  // }, [selectedPageId]);

  const setSelectedPageId = async (reportPageId, reportPages) => {
    const { pageIndex: previousPageIndex, page: previousPage } =
      getPage(selectedPageId);
    console.log('selectedPageId: ', selectedPageId);
    console.log('previousPage: ', previousPage);

    if (previousPage?.isThumbnailOutOfDate) {
      const thumbnailImageDataUri = await createThumbnail();
      console.log('thumbnailImageDataUri: ', thumbnailImageDataUri);
      //       console.log('thumbnail123: ', thumbnail);
      //       // copyOfPage.thumbnail = thumbnail;
      updatePage(
        { thumbnailImageDataUri, isThumbnailOutOfDate: false },
        selectedPageId,
        false,
        reportPages
      );
      // setIsThumbnailOutOfDate(false);
    }
    updateSelectedPageId(reportPageId);
  };
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  const [isThumbnailOutOfDate, setIsThumbnailOutOfDate] = useState(false);
  // console.log('isThumbnailOutOfDate: ', isThumbnailOutOfDate);
  const [
    widgetIdThatTriggeredContextMenu,
    setWidgetIdThatTriggeredContextMenu
  ] = useState();

  const getPage = (pageId) => {
    let pageIndex = report?.pages?.findIndex((_page) => _page.id === pageId);
    const page_ = report.pages[pageIndex];
    return { pageIndex, page: page_ };
  };

  const updatePage = (
    update,
    pageId = selectedPageId,
    updateDb,
    reportPages
  ) => {
    // console.log('debug 9256>running update widget 1');
    const copyOfPages = [...(reportPages?.length ? reportPages : report.pages)];
    const copyOfReport = { ...report };

    const { pageIndex, page } = getPage(pageId);
    if (pageIndex < 0) {
      // console.log('debug 9256>running update widget>pageIndex: ', pageIndex);
      return;
    }

    let copyOfPage = { ...page, ...update };

    if (copyOfPage?.isDataFrozen !== reportPage?.isDataFrozen) {
      let copyOfWidgets = [...(reportPage?.widgets || [])];
      copyOfWidgets = calcWidgets(
        isReportBuilderOpen,
        copyOfPage,
        copyOfWidgets,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      copyOfPage.widgets = copyOfWidgets;
    }

    copyOfPages.splice(pageIndex, 1, copyOfPage);
    copyOfReport.pages = copyOfPages;

    setReport(copyOfReport);
  };

  // const updateThumbnail = async (pageId) => {
  //   console.log('debug 9256>running update widget 1');
  //   const copyOfPages = [...report.pages];
  //   const copyOfReport = { ...report, pages: copyOfPages };
  //   // let pageIndex = copyOfReport.pages.findIndex(
  //   //   (_page) => _page.id === pageId
  //   // );

  //   console.log(
  //     'debug 9256>running update widget>selectedPageId: ',
  //     selectedPageId
  //   );
  //   console.log('debug 9256>running update widget>pageId: ', pageId);

  //   const { pageIndex, page } = getPage(pageId);
  //   if (pageIndex < 0) {
  //     console.log('debug 9256>running update widget>pageIndex: ', pageIndex);
  //     return;
  //   }
  //   console.log('debug 9256>running update widget 2');
  //   let copyOfPage = { ...page };
  //   copyOfPage.thumbnail = createThumbnail(pageId);
  // };

  const updateWidget = async (
    widget,
    widgetIndex_,
    pageId = selectedPageId,
    updateDb
  ) => {
    const copyOfPages = [...report.pages];
    const copyOfReport = { ...report, pages: copyOfPages };
    // let pageIndex = copyOfReport.pages.findIndex(
    //   (_page) => _page.id === pageId
    // );

    const { pageIndex, page } = getPage(pageId);
    if (pageIndex < 0) {
      // console.log('debug 9256>running update widget>pageIndex: ', pageIndex);
      return;
    }
    // console.log('debug 9256>running update widget 2');
    let copyOfPage = { ...page };

    let copyOfWidgets = [...(copyOfPage?.widgets || [])];
    let widgetIndex = copyOfWidgets.findIndex(
      (_widget) => _widget.id === widget.id
    );

    let copyOfWidget = { ...widgets[widgetIndex], ...widget };

    copyOfWidget = calcWidget(
      isReportBuilderOpen,
      copyOfPage,
      copyOfWidget,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData
    );
    copyOfWidgets.splice(widgetIndex, 1, copyOfWidget);
    copyOfPage.widgets = copyOfWidgets;
    if (updateDb) {
      // setIsThumbnailOutOfDate(true);
      copyOfPage.isThumbnailOutOfDate = true;
    }
    // copyOfPage.thumbnail = await createThumbnail(pageId);
    // console.log('copyOfPage.thumbnail: ', copyOfPage.thumbnail);
    copyOfPages.splice(pageIndex, 1, copyOfPage);
    copyOfReport.pages = copyOfPages;
    // console.log('debug 9256>running update widget 3');

    setReport(copyOfReport);
    // console.log('isThumbnailOutOfDate>yeee1');
    // setWidgets(copyOfWidgets);
  };
  const setWidgets = async (widgets, pageId = selectedPageId, updateDb) => {
    // console.log('debug 9256>setWidgets');
    const copyOfPages = [...report.pages];
    const copyOfReport = { ...report, pages: copyOfPages };

    const { pageIndex, page } = getPage(pageId);
    let copyOfPage = { ...page };

    let copyOfWidgets = [...widgets];

    copyOfWidgets = calcWidgets(
      isReportBuilderOpen,
      copyOfPage,
      copyOfWidgets,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData
    );

    copyOfPage.widgets = copyOfWidgets;

    if (updateDb) {
      // console.log('updateDb: ', updateDb);
      // setIsThumbnailOutOfDate(true);
      copyOfPage.isThumbnailOutOfDate = true;
    }

    // const thumbnail = await createThumbnail(pageId);
    // copyOfPage.thumbnail = thumbnail;
    // console.log('copyOfPage.thumbnail: ', copyOfPage.thumbnail);

    copyOfPages.splice(pageIndex, 1, copyOfPage);
    copyOfReport.pages = copyOfPages;

    setReport(copyOfReport);

    return () => {
      // console.log('updateWidgets>test: ');
      // setIsThumbnailOutOfDate(true);
    };
  };

  const calcPageWidgets = async (reportPage) => {
    const copyOfReportPage = { ...reportPage };
    const copyOfWidgets = [...reportPage.widgets];
    // console.log('copyOfWidgets: ', copyOfWidgets);
    const updatedCopyOfWidgets = await calcWidgets(
      isReportBuilderOpen,
      copyOfReportPage,
      copyOfWidgets,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData
    );
    copyOfReportPage.widgets = updatedCopyOfWidgets;
    // console.log('updatedCopyOfWidgets: ', updatedCopyOfWidgets);
    return { ...copyOfReportPage, widgets: updatedCopyOfWidgets };
  };
  // console.log('report: ', report);

  const [selectedPage, setSelectedPage] = useState();
  useEffect(() => {
    const { pageIndex, page } = getPage(selectedPageId);
    setSelectedPage(page);
  }, [report.pages, selectedPageId]);

  const [widgets, updateWidgets] = useState([]);
  // useEffect(() => {
  //   // updateWidgets(selectedPage?.widgets);
  // }, [selectedPage?.widgets]);

  // useEffect(() => {
  //   console.log('isThumbnailOutOfDate: ', isThumbnailOutOfDate);
  //   if (isThumbnailOutOfDate) {
  //     setTimeout(async () => {
  //       const thumbnail = await createThumbnail();
  //       console.log('thumbnail123: ', thumbnail);
  //       // copyOfPage.thumbnail = thumbnail;
  //       updatePage(selectedPageId, { thumbnail });
  //     }, 200);
  //     setIsThumbnailOutOfDate(false);
  //   }
  // }, [isThumbnailOutOfDate]);

  // console.log('ReportBuilderContext>selectedPage: ', selectedPage);
  // console.log('ReportBuilderContext>widgets: ', widgets);

  // reportPages,
  // setReportPage,
  // reportPageIdThatTriggeredContextMenu,

  const setReportPages = (reportPages_) => {
    // console.log('reportPages_: ', reportPages_);
    const copyOfReport = { ...report, pages: reportPages_ };

    setReport(copyOfReport);
  };
  const reportPage = (report?.pages || [])?.filter(
    (obj) => obj.id === selectedPageId
  )[0];

  // ################################################################################
  // ########################### calc data ##########################################
  // ################################################################################

  useEffect(() => {
    if (selectedPage?.widgets) {
      const newWidgets = calcWidgets(
        isReportBuilderOpen,
        selectedPage,
        [...selectedPage?.widgets],
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      // console.log('newWidgets: ', newWidgets);
      setWidgets(newWidgets);
    }

    // for (let )
  }, [listOfFilteredDataSourceRows, isReportBuilderOpen, aggregatedDateRange]);

  // useEffect(() => {
  //   if (selectedPage?.widgets) {
  //     const newWidgets = calcWidgets(
  //       [...selectedPage?.widgets],
  //       listOfFilteredDataSourceRows
  //     );
  //     console.log('newWidgets: ', newWidgets);
  //     setWidgets(newWidgets);
  //   }

  //   // for (let )
  // }, [selectedPage?.widgets]);

  const [refetchAssets, setRefetchAssets] = useState(0);

  // ################################################################################
  // ########################### end of calc data ##########################################
  // ################################################################################
  return (
    <ReportBuilderContext.Provider
      value={{
        contextMenuAnchorPosition,
        setContextMenuAnchorPosition,
        reportPageListContextMenuAnchorPosition,
        setReportPageListContextMenuAnchorPosition,
        widgetIdThatTriggeredContextMenu,
        setWidgetIdThatTriggeredContextMenu,
        report,
        setReport,
        widgets: selectedPage?.widgets || [],
        setWidgets,
        updateWidget,
        selectedPage,
        reportPages: report?.pages || [],
        setReportPages,
        selectedPageId,
        setSelectedPageId,
        reportPage,
        reportPageIdThatTriggeredContextMenu,
        setReportPageIdThatTriggeredContextMenu,
        updateReportPage: updatePage,
        currentlyDraggingWidget,
        setCurrentlyDraggingWidget,
        widgetIdsWithAxisSettingsOpen,
        isAxisMenuOpen,
        toggleIsAxisSettingsOpen,
        refetchAssets,
        setRefetchAssets,
        calcPageWidgets
      }}
    >
      {props.children}
    </ReportBuilderContext.Provider>
  );
};
