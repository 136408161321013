import React from 'react';
import ReportAssetName from './ReportAssetName';

function ReportAsset({
  reportAsset,
  reportAssetIndex,
  reportAssets,
  setReportAssets
}) {
  // console.log('reportAsset.imageDataUri: ', reportAsset.imageDataUri);
  if (!reportAsset.imageDataUri) {
    return <div>loeading</div>;
  }
  console.log('reportAsset: ', reportAsset);
  return (
    <div
      style={{
        margin: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'rgba(150,150,150,0.1)',
        borderRadius: '5px',
        padding: '10px'
      }}
    >
      <div style={{ fontSize: '12px', width: '100%' }}>
        {/* <div>{reportAsset.name}</div> */}
        <ReportAssetName
          reportAsset={reportAsset}
          reportAssetIndex={reportAssetIndex}
          reportAssets={reportAssets}
          setReportAssets={setReportAssets}
        />
        <div>{reportAsset.type}</div>
      </div>
      <div style={{ width: '200px', maxHeight: '200px' }}></div>
      <img
        id={reportAsset.id}
        src={reportAsset.imageDataUri}
        style={{
          objectFit: 'contain',
          // backgroundImage: `url(${reportAsset.imageDataUri})`,
          // backgroundRepeat: 'no-repeat',
          //   aspectRatio: reportAsset.imageOriginalAspectRatio,
          width: '200px',
          maxHeight: '200px',
          maxWidth: '200px'
          //   //   height: '300px'
        }}
      />
    </div>
  );
}

export default ReportAsset;
