import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { LineChart } from '@mui/x-charts/LineChart';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

import { prepDataObject } from './prepDataObject';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  colorPalette
} from './../dictionary';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

function LineChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  // const { updateWidget } = useContext(ReportBuilderContext);

  const key = widget.metrics?.[0] || 'cost';

  // console.log('lineChartWidget>widget: ', widget);

  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop
  } = useContext(InterfaceContext);
  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);
  const { aggregatedDateRange, startDateForLastDaysData } =
    useContext(InterfaceContext);

  const { isReportBuilderOpen } = useContext(DialogContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([new Date()]);

  const [intervalRange, setIntervalRange] = useState([]);

  useEffect(() => {
    return;
    setIsLoading(true);
    if (
      !isReportBuilderOpen ||
      reportPage?.isDataFrozen ||
      widget?.isDataFrozen
    ) {
      return;
    }
    // console.log('reportPage.isDataFrozen: ', reportPage.isDataFrozen);
    // console.log('reportPage123: ', reportPage);

    const dataSourceData = listOfFilteredDataSourceRows.filter(
      (row) =>
        [
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'manualDataEntry'
        ].includes(row.type) &&
        (row.endDate >= formatDateYYYYMMDD(startDateForLastDaysData) ||
          !row.endDate)
    );

    let widgetGroupByKey = '';

    switch (widget.dimensions[0]) {
      case 'documentType':
        widgetGroupByKey = 'documentType';
        break;
      case 'name':
        widgetGroupByKey = 'name';
        break;
    }

    let tempGroupBy = {};
    // console.log('widget.dimensions: ', widget.dimensions);

    // console.log('widget.dimensions.length: ', widget.dimensions.length);
    if (widget.dimensions.length) {
      tempGroupBy = Object.groupBy(
        dataSourceData,
        (row) => row[widgetGroupByKey]
      );
    } else {
      tempGroupBy = { default: dataSourceData };
    }

    // console.log('PieChartWidget>testGroupBy: ', testGroupBy);
    // console.log('tempGroupBy: ', tempGroupBy);

    const dimensionKeys = Object.keys(tempGroupBy);

    // console.log('dimensionKeys: ', dimensionKeys);
    // console.log('tempGroupBy: ', tempGroupBy);
    // console.log('widget.metrics111: ', widget.metrics);

    const allSeries = [];
    let intervalRange_ = [];

    // console.log('debug9567>row155>intervalRange_: ', intervalRange_);
    // console.log('debug9567>row155>widget.interval: ', widget.interval);
    switch (widget.interval) {
      case 'month':
        const formattedDates = aggregatedDateRange.map((date) =>
          formatDateYYYYMMDD(date)
        );
        intervalRange_ = Object.groupBy(formattedDates, (date) =>
          date.slice(0, 7)
        );
        intervalRange_ = Object.keys(intervalRange_);

        if (new Date(formattedDates[0]).getDate() !== 1) {
          intervalRange_.shift();
        }
        // console.log('debug9567>row164>intervalRange_: ', intervalRange_);
        break;
      default:
        intervalRange_ = aggregatedDateRange.map((date) =>
          formatDateYYYYMMDD(date)
        );
    }

    // console.log('debug9567>row167>intervalRange_: ', intervalRange_);

    setIntervalRange(intervalRange_);
    // aggregatedDateRange;

    for (let dimensionKey of dimensionKeys) {
      // data source
      // console.log('debug2233>row103>dimensionKey: ', dimensionKey);
      let newSeriesData = [];

      let allPropagationData = [];
      for (let row of tempGroupBy[dimensionKey]) {
        allPropagationData = [
          ...allPropagationData,
          ...(row?.total_list_of_propagation_data || [])
        ];
      }

      for (let row of allPropagationData) {
        let formattedDate = formatDateYYYYMMDD(row.date_start);
        row.date = formattedDate;
        row.month = formattedDate.slice(0, 7);
      }
      const dimensionsDate = Object.groupBy(
        allPropagationData,
        (obj) => obj[widget.interval]
      );

      // switch (widget.interval) {
      //   case 'month':
      //     intervalRange = Object.keys(dimensionsDate);
      //   default:
      //     intervalRange = aggregatedDateRange;
      // }
      // console.log('intervalRange_: ', intervalRange_);
      for (let intervalUnit of intervalRange_) {
        let sumObject = {};

        for (let dataObj of dimensionsDate?.[intervalUnit] || []) {
          for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
            // console.log('dataObj[metric]: ', dataObj[metric]);
            sumObject[metric] =
              (sumObject[metric] || 0) + (dataObj[metric] || 0);
          }
        }

        let newValue = null;

        sumObject = prepDataObject(sumObject);
        // sumObject.date = date;
        // sumObject.month = new Date(date).getMonth() + 1;
        sumObject.intervalUnit = intervalUnit;

        newValue = sumObject[widget.metrics[0]];
        newSeriesData.push(sumObject);
      }
      // console.log('dimensionsDate: ', dimensionsDate);
      // console.log('newSeriesData11: ', newSeriesData);

      // switch (true) {
      //   case widget.interval === 'month':
      //     const intervalGroupBy = Object.groupBy(
      //       newSeriesData,
      //       (obj) => obj.month
      //     );
      //     console.log('intervalGroupBy: ', intervalGroupBy);
      //     break;
      // }
      allSeries.push({
        label:
          documentTypeToShortNameDict[dimensionKey] ||
          (dimensionKey !== 'default' ? dimensionKey : undefined),
        data: (newSeriesData || []).map((obj) => obj[key]), //Math.round(obj?.[key])),
        showMark: false
      });

      // for (let row of tempGroupBy[dimensionKey]) {
      //   const dimensionsDate = Object.groupBy(
      //     row?.total_list_of_propagation_data || [],
      //     (obj) => formatDateYYYYMMDD(obj.date_start)
      //   );

      //   for (let date of aggregatedDateRange) {
      //     console.log('date111: ', date);
      //     let sumObject = {};
      //     console.log('dimensionsDate[date]: ', dimensionsDate[date]);
      //     for (let dataObj of dimensionsDate[date]) {
      //       for (let metric of widget.metrics) {
      //         console.log('dataObj[metric]: ', dataObj[metric]);
      //         sumObject[metric] += dataObj[metric] || 0;
      //       }
      //     }
      //     newSeriesData.push(sumObject);
      //   }
      //   console.log('dimensionsDate: ', dimensionsDate);
      //   console.log(' newSeriesData: ', newSeriesData);
      //   allSeries.push({ data: newSeriesData });
      // }
    }

    // console.log('allSeries: ', allSeries);

    // const seriesWithData = allSeries.filter((serie) =>
    //   serie.data.some((val) => Boolean(val))
    // );
    // console.log('seriesWithData: ', seriesWithData);
    setSeries(allSeries);
    //   let dimensionsValue = 0;

    //   for (let row of testGroupBy[dimensionKey]) {
    //     dimensionsValue += row[key];
    //   }
    //   let seriesData = {
    //     // label: documentTypeToNameDict[dimensionKey],
    //     value: dimensionsValue
    //   };

    //   switch (widgetGroupByKey) {
    //     case 'documentType':
    //       seriesData.label = documentTypeToNameDict[dimensionKey];
    //       break;

    //     case 'name':
    //       seriesData.label =
    //         dimensionKey.slice(0, 20) + (dimensionKey.length > 20 ? '...' : '');
    //       break;
    //   }
    //   newSeriesData.push(seriesData);
    // }

    // let totalValue = 0;
    // for (let row of newSeriesData) {
    //   totalValue += row?.value || null;
    // }

    // for (let row of newSeriesData) {
    //   row.share = Math.round((row?.value / totalValue) * 100);
    //   row.value = Math.round(row?.value);
    // }
    // console.log('totalValue: ', totalValue);
    // setSeriesData(newSeriesData);

    let _interval = [];
    switch (widget.interval) {
      case 'month':
        _interval = intervalRange_;
        break;
      default:
        // let dates_ = widget.dates?.map((date) => new Date(date));

        // console.log('row313>intervalRange_: ', intervalRange_);
        _interval = intervalRange_;
    }

    // console.log('_interval: ', _interval);

    // console.log('debug9933>allSeries: ', allSeries);
    let copyOfWidget = { ...widget };
    copyOfWidget = {
      ...copyOfWidget,
      data: [...allSeries],
      dates: [..._interval]
    };
    // console.log('copyOfWidget: ', copyOfWidget);

    updateWidget(copyOfWidget, widgetIndex);

    // setDates(dates);
    setIsLoading(false);
  }, [
    listOfData,
    aggregatedDateRange,
    widget.id,
    widget.metrics,
    widget.dimensions,
    widget.interval,
    listOfFilteredDataSourceRows,
    selectedDateStop,
    setSelectedDateStop,
    // reportPage?._id, // dont use
    reportPage?.isDataFrozen,
    widget?.isDataFrozen,
    isReportBuilderOpen
  ]);

  // const data = {
  //   data: seriesData
  //   // valueFormatter
  // };

  let xAxis = {};

  switch (widget.interval) {
    case 'month':
      // const monthNames = [
      //   'January',
      //   'February',
      //   'March',
      //   'April',
      //   'May',
      //   'June',
      //   'July',
      //   'August',
      //   'September',
      //   'October',
      //   'November',
      //   'December'
      // ];

      xAxis = [
        {
          label: 'Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget.dates || [], // xAxisData,
          scaleType: 'point' // 'band'
          // valueFormatter: (month) => {
          //   return month;
          // }
          // valueFormatter: (date) => {
          //   const formattedDate = formatDateYYYYMMDD(date);
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }
      ];
      break;
    default:
      let dates_ = widget.dates?.map((date) => new Date(date));
      // console.log('widget.dates: ', widget.dates);
      xAxis = [
        {
          label: 'Date',
          data: dates_ || [], // xAxisData,
          tickInterval: dates_ || [], // xAxisData,
          scaleType: 'time',
          valueFormatter: (date) => {
            // console.log('date: ', date);
            const formattedDate = formatDateYYYYMMDD(date);
            // console.log('formattedDate: ', formattedDate);
            return formattedDate;
          } //(date) => dayjs(date).format('MMM D')
        }
      ];
  }

  // console.log('widget?.data: ', widget?.data);

  if (widget?.data?.[0]?.data?.length !== widget?.dates?.length) {
    // console.log('fail: widget?.data?.length: ', widget?.data?.data?.length);
    // console.log('fail: widget?.dates?.length: ', widget?.dates?.length);
    return <></>;
  }

  // if (isLoading) {
  //   return <div></div>;
  // }
  return (
    // <div onMouseUpHandler={onMouseUpHandler}>

    // <div
    //   style={{
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     // justifyContent: 'space-between',
    //     justifyContent: 'space-between',
    //     alignItems: 'center'
    //     // position: 'relative',
    //     // width: widget.width.split('px')[0] * 0.9,
    //     // height: widget.height.split('px')[0] * 0.8,
    //     // padding: '0px'
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       fontWeight: 'bold'
    //     }}
    //   >
    <div
      key={widget.id}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.width.split('px')[0] * 0.9,
        // height: widget.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {/* <div
          style={{
            borderRadius: '0px 0px 5px 5px',
            background: 'rgba(150,150,150,0.1)',
            padding: '0px 7px 2px 7px'
          }}
        > */}
        {widget.title}
        {/* </div> */}
      </div>
      <div
        style={{
          display: 'flex'
          // padding: '0px 3px'
        }}
      >
        <LineChart
          colors={colorPalette} // Use palette
          // xAxis={[
          //   new Date('2024-08-01'),
          //   new Date('2024-08-02'),
          //   new Date('2024-08-03'),
          //   new Date('2024-08-04'),
          //   new Date('2024-08-05'),
          //   new Date('2024-08-06'),
          //   new Date('2024-08-07')
          // ]}

          xAxis={xAxis}
          // {[
          //   {
          //     label: 'Date',
          //     data: dates_, // xAxisData,
          //     tickInterval: dates_, // xAxisData,
          //     scaleType: 'time',
          //     valueFormatter: (date) => {
          //       const formattedDate = formatDateYYYYMMDD(date);
          //       // console.log('formattedDate: ', formattedDate);
          //       return formattedDate;
          //     } //(date) => dayjs(date).format('MMM D')
          //   }
          // ]}
          series={
            // [
            //   {
            //     data: [1, 2, 3, 4, 5, 6, 7]
            //   }
            // ]
            // widget?.data || []
            widget?.data?.[0]?.data?.length === widget?.dates?.length
              ? widget?.data
              : []

            // [
            //   {
            //     // arcLabel: (item) => `${item.share}%`,
            //     // arcLabelMinAngle: 35,
            //     // arcLabelRadius: '60%',
            //     ...data
            //   }
            // ]
          }
          margin={{
            left: 70
            // right: 60
            // top: 80,
            // bottom: 80,
          }}
          slotProps={{
            legend: {
              hidden: (widget?.data || [])?.length > 10,
              // direction: 'column',
              // position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              padding: { top: 0, left: 10, right: 10 },
              labelStyle: {
                fontSize: 12
                //fill: 'blue'
              }
            }
          }}
          width={widget.width.split('px')[0]}
          height={
            widget.height.split('px')[0] - (widget?.dimensions?.length ? 20 : 0)
          }
        />
      </div>
    </div>
  );
}

export default LineChartWidget;
