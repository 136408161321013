import React, { useState, useEffect, useContext, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { SnackbarProvider, useSnackbar } from 'notistack';

function ImageWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler,
  // widgetType,
  dropOnWidget
}) {
  const screenImageRef = useRef();

  const mockupRef = useRef();

  const widgetContentRef = useRef();
  // console.log('mockupWidget>widget: ', widget);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  // const logoUrl =
  //   currentManagerAccount?.logo ||
  //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  // console.log('logoUrl: ', logoUrl);

  const [imageUrl, setImageUrl] = useState();

  const [dragActive, setDragActive] = React.useState(false);

  // useEffect(() => {
  //   console.log(`widgetId: ${widget.id}, imageUrl: ', ${imageUrl}`);
  // }, [imageUrl]);

  const [imgNaturalHeight, setImgNaturalHeight] = useState(
    widget.imgNaturalHeight
  );
  const [imgNaturalWidth, setImgNaturalWidth] = useState(
    widget.imgNaturalWidth
  );
  useEffect(() => {
    if (!imageUrl && widget?.imageUrl) {
    }
    setImageUrl(widget.imageUrl);
  }, [widget.imageUrl]);

  const onDropHandler = async (e) => {
    // console.log('widget12312312: ', widget);
    e.preventDefault();
    let copyOfWidget = { ...widget };

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    const file = e?.dataTransfer?.files[0];
    console.log('file: ', file);

    if (
      isFileTransfer &&
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    ) {
      enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
        variant: 'error'
      });
      return;
    }
    if (file) {
      e.stopPropagation();

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        setImgNaturalHeight(this.naturalHeight);
        setImgNaturalWidth(this.naturalWidth);

        copyOfWidget = {
          ...copyOfWidget,
          imgNaturalHeight: img.naturalHeight,
          imgNaturalWidth: img.naturalWidth,
          imageOriginalAspectRatio: img.naturalWidth / img.naturalHeight
        };

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        // console.log('dataURL: ', dataUri);

        copyOfWidget.screenImageDataUri = dataUri;
        updateWidget(copyOfWidget, widgetIndex);
      };

      setDragActive(false);
      // const files = e.dataTransfer.files;
      // console.log('files: ', files);
      // return;

      // for (let file of files) {
      // let reader = new FileReader();

      // reader.onload = async () => {
      //   // console.log('reader.result: ', reader.result);
      //   const binaryStr = reader.result;

      //   // setImageUrl(binaryStr);

      //   copyOfWidget = {
      //     ...copyOfWidget,
      //     // imageUrl: binaryStr,
      //     screenImageDataUri: binaryStr
      //   };
      //   updateWidget(copyOfWidget, widgetIndex);

      //   setDragActive(false);

      //   // console.log('binaryStr: ', binaryStr);
      // };

      // reader.readAsDataURL(file);

      // const image = reader.src;

      // if (image.src) {
      //   setImageUrl(image.src);
      // }
      // }

      // handleFiles(e.dataTransfer.files);
    }
    // e.stopPropagation();
    // dropOnWidget(e, widget, widgetIndex);
  };

  // useEffect(() => {
  //   if (widget.naturalHeight) {
  //     setImgNaturalHeight(widget.naturalHeight);
  //   }
  //   if (widget.naturalWidth) {
  //     setImgNaturalWidth(widget.naturalWidth);
  //   }
  // }, []);

  // console.log('mockupWidget: ', widget);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (!isFileTransfer) {
      return;
    }
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // console.log('mockupRef?.current?.height: ', mockupRef?.current?.height);
  // console.log('mockupRef: ', mockupRef);
  // console.log(
  //   'widgetContentRef?.current?.height: ',
  //   widgetContentRef?.current?.clientHeight
  // );

  // console.log('widgetContentRef: ', widgetContentRef);
  // console.log('widget: ', widget);

  // console.log(
  //   'test9456>mockupRef?.current?.width / mockupRef?.current?.height: ',
  //   mockupRef?.current?.width / mockupRef?.current?.height
  // );

  // console.log(
  //   "test9456>(widget.width.split('px')[0] / widget.height.split('px')[0]): ",
  //   widget.width.split('px')[0] / widget.height.split('px')[0]
  // );
  // console.log('widget.screenImageDataUri: ', widget.screenImageDataUri);
  return (
    <div
      ref={widgetContentRef}
      key={widget.id}
      onDrop={(e) => {
        // console.log('widget12312312: ', widget);
        e.preventDefault();
        // e.stopPropagation();
        // dropOnWidget(e, widget, widgetIndex);
      }}
      onMouseUpHandler={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {dragActive ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(e) => onDropHandler(e)}
        >
          <div
            style={{
              height: '70%',
              width: '70%',
              minWidth: '100px',
              minHeight: '50px',
              border: '1px dashed lightgrey',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px'
            }}
          >
            DROP IMAGE HERE <FileUploadIcon />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {widget?.screenImageDataUri && (
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                // position: 'relative',
                // aspectRatio: 345 / 711,

                // position: 'absolute',
                // borderRadius:
                //   345 / 711 <
                //     (widget?.width.split('px')[0] /
                //       widget?.height.split('px')[0]) *
                //       0.95 ||
                //   345 / 711 >
                //     (widget?.width.split('px')[0] /
                //       widget?.height.split('px')[0]) *
                //       1.1
                //     ? '0px'
                //     : '50px',

                overflow: 'hidden',
                // background: 'green',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                id={'screen_' + widget.id}
                style={{
                  background:
                    'linear-gradient(335deg,rgb(245,245,245), rgb(255,255,255))', // 'white',
                  // aspectRatio: (345 / 711) * 0.95,
                  // width: widget?.originalWidth * 0.95,
                  // height: widget?.originalHeight * 0.95,
                  width: widget?.width.split('px')[0] * 0.945 + 'px',
                  height: widget?.height.split('px')[0] * 0.858 + 'px',
                  marginBottom: '2px',
                  // position: 'absolute',
                  // borderRadius: '50px',

                  overflow: 'hidden',
                  // background: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                  // zIndex: 100
                }}
              >
                <div
                  onDragEnter={handleDrag}
                  onDrop={(e) => onDropHandler(e)}
                  draggable={false}
                  style={{
                    // height: '100%',
                    // height:'auto',
                    aspectRatio: widget?.imageOriginalAspectRatio, //`${widget.imgNaturalWidth} / ${widget.imgNaturalHeight}`,

                    width: '100%',
                    // minWidth: widget?.width * 0.995,
                    // margin: '0.1%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    // border-radius: 50%;
                    // border: solid 1px #e6e6e6;
                    // margin: '0 auto',
                    // overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    // img: {
                    //   height: '100%',
                    //   width: '100%'
                    // }
                    // height: '100%',
                    backgroundImage: `url(${widget.screenImageDataUri})`
                  }}
                ></div>

                {/* <img
                  onDragEnter={handleDrag}
                  onDrop={(e) => onDropHandler(e)}
                  draggable={false}
                  src={widget.imageUrl}
                  alt="Logo"
                  style={{
                    width: '100%',
                    // borderRadius: '5px',
                    display: 'block',
                    // height: '97%',
                    // width:'97%',
                    objectFit: 'cover',
                    overflow: 'hidden'
                    // opacity: 0.5
                    // alignSelf: 'baseline',
                    // margin: '0px 3px',
                    // marginTop: '12px'
                  }}
                /> */}
              </div>
            </div>
          )}

          <img
            ref={mockupRef}
            onDragEnter={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            draggable={false}
            src={widget.mockupImageDataUri}
            alt="Logo"
            style={{
              top: '0px',
              left: '0px',
              position: 'absolute',
              borderRadius: '5px',
              display: 'block',
              height: '100%',
              objectFit: 'cover',
              overflow: 'hidden'
              // zIndex: 0
              // opacity: 0.5
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ImageWidget;
