import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

export const prepDataObject = (dataObject) => {
  const copyOfDataObject = { ...dataObject };

  for (let metric of nonSumEvalFields) {
    let newValue = null;
    switch (metric) {
      case 'cpm':
        newValue =
          dataObject['impressions'] && dataObject['cost']
            ? dataObject['cost'] / (dataObject['impressions'] / 1000)
            : null;
        break;

      case 'vCpm':
        newValue =
          dataObject['viewableImpressions'] && dataObject['cost']
            ? dataObject['cost'] / (dataObject['viewableImpressions'] / 1000)
            : null;
        break;

      case 'cpcv':
        newValue = dataObject['videoCompletions']
          ? dataObject['cost'] / dataObject['videoCompletions']
          : null;
        break;

      case 'cpc':
        newValue = dataObject['clicks']
          ? dataObject['cost'] / dataObject['clicks']
          : null;
        break;

      case 'ctr':
        newValue = dataObject['impressions']
          ? dataObject['clicks'] / dataObject['impressions']
          : null;
        break;

      case 'action01Roas':
        newValue = dataObject['cost']
          ? dataObject['action01Value'] / dataObject['cost']
          : null;
        break;

      case 'action01Cpa':
        newValue = dataObject['action01Count']
          ? dataObject['cost'] / dataObject['action01Count']
          : null;
        break;

      case 'action01Vpa':
        newValue = dataObject['action01Count']
          ? dataObject['action01Value'] / dataObject['action01Count']
          : null;
        break;

      case 'action02Roas':
        newValue = dataObject['cost']
          ? dataObject['action02Value'] / dataObject['cost']
          : null;
        break;

      case 'action02Cpa':
        newValue = dataObject['action02Count']
          ? dataObject['cost'] / dataObject['action02Count']
          : null;
        break;

      case 'action02Vpa':
        newValue = dataObject['action02Count']
          ? dataObject['action02Value'] / dataObject['action02Count']
          : null;
        break;

      case 'action03Roas':
        newValue = dataObject['cost']
          ? dataObject['action03Value'] / dataObject['cost']
          : null;
        break;

      case 'action03Cpa':
        newValue = dataObject['action03Count']
          ? dataObject['cost'] / dataObject['action03Count']
          : null;
        break;

      case 'action03Vpa':
        newValue = dataObject['action03Count']
          ? dataObject['action03Value'] / dataObject['action03Count']
          : null;
        break;
    }

    switch (true) {
      case ['ctr'].includes(metric):
        // console.log('newValue: ', newValue);
        newValue = Math.round((newValue + Number.EPSILON) * 10000) / 100; //.toLocaleString();
        break;

      default:
        newValue = Math.round((newValue + Number.EPSILON) * 100) / 100; //.toLocaleString();
    }
    copyOfDataObject[metric] = newValue;
  }

  return copyOfDataObject;
};
