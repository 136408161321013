import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportPageQuery } from '../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { v4 as uuidv4 } from 'uuid';

import { useSnackbar } from 'notistack';

function ReportPageInfo({ selectedReportPage, setSelectedReportPage }) {
  const { enqueueSnackbar } = useSnackbar();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const { reportPages, setReportPages, setSelectedPageId, calcPageWidgets } =
    useContext(ReportBuilderContext);
  // console.log('debug2465>reportPages: ', reportPages);

  const [reportPage, setReportPage] = useState();
  const [isIncludingWidgets, setIsIncudingWIdget] = useState(false);

  const [variables, setVariables] = useState();

  useEffect(() => {
    setVariables({
      input: {
        managerAccountId: currentManagerAccountId,
        reportPageId: selectedReportPage?._id,
        isIncludingWidgets: false
      }
    });
  }, [selectedReportPage?._id]);

  // const variables = ;

  const [loadReportPage, { called, loading, data }] = useLazyQuery(
    reportPageQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (
      currentManagerAccountId &&
      selectedReportPage &&
      selectedReportPage?._id
    ) {
      loadReportPage();
    }
  }, [currentManagerAccountId, selectedReportPage]);

  useEffect(() => {
    if (data?.reportPage && called) {
      if (variables.input.isIncludingWidgets) {
        const newReportPage = { ...data?.reportPage };
        newReportPage.widgets = [
          ...newReportPage?.widgets?.map((widget) => ({
            ...widget,
            id: uuidv4(),
            metrics: widget?.metrics.map((metricObj) => ({
              ...metricObj,
              id: uuidv4()
            }))
          }))
        ];

        delete newReportPage._id;
        newReportPage.id = uuidv4();

        const setDate = async () => {
          const updatedNewRportPage = await calcPageWidgets(newReportPage);
          // console.log(
          //   'ReportPageInfo>updatedNewRportPage: ',
          //   updatedNewRportPage
          // );

          // ################################

          setReportPages([...reportPages, updatedNewRportPage]);

          setSelectedPageId(newReportPage.id, [
            ...reportPages,
            updatedNewRportPage
          ]);

          enqueueSnackbar('Template imported', {
            variant: 'success'
          });
        };
        setDate();
        // ########### test ###############

        // setReportPage(null);
      } else {
        setReportPage(data?.reportPage);
      }
    }
  }, [data]);

  const aspectRatio =
    reportPage?.width?.split('px')[0] / reportPage?.height?.split('px')[0];

  // console.log('selectedReportPage: ', selectedReportPage);
  // console.log('reportPage: ', reportPage);

  const onClickImportHandler = async () => {
    // setReportPages(true);
    // variables.input.isIncludingWidgets = true;
    setVariables({
      ...variables,
      input: { ...variables.input, isIncludingWidgets: true }
    });

    // const res = await loadReportPage();
    // console.log('ReportPageInfo>res: ', res);
  };

  if (!reportPage) {
    return <></>;
  }
  return (
    <div
      style={{
        height: '100%',
        width: '1200px',
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px 10px',
        padding: '10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)'
      }}
    >
      <div
        style={{
          // margin: '5px',
          borderRadius: '5px',
          width: '100%',
          aspectRatio: aspectRatio,
          // width: '400px',
          backgroundSize: 'cover',
          // width: '170px',

          backgroundImage: `url(${reportPage?.previewImageDataUri})`,
          backgroundRepeat: 'no-repeat'
          // border:
          //   reportPage?._id === selectedReportPage?._id
          //     ? theme?.palette?.mode === 'dark'
          //       ? '1px solid rgba(255,255,255,1)'
          //       : 'red'
          //     : '1px solid rgba(255,255,255,0)'
        }}
      >
        {/* <div
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            padding: '1px 7px',
            background: '#a7268344',
            borderRadius: '0px 5px 0px 5px',
            fontSize: '10px',
            fontWeight: 'bold'
          }}
        >
          {reportPage?.name}
        </div> */}
      </div>
      <div
        style={{
          width: '100%',
          // minHeight: '200px',
          // margin: '10px',
          marginTop: '10px',
          padding: '10px',
          paddingLeft: '30px',
          borderRadius: '5px',
          background: 'rgba(150,150,150,0.3)',
          fontSize: '14px',
          fontWeight: 'bold',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '50px',
              marginRight: '20px'
            }}
          >
            Name:
          </span>{' '}
          <span>{reportPage?.name}</span>
        </div>
      </div>
      <div
        style={{
          margin: '10px 0px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end'
        }}
      >
        <Button onClick={onClickImportHandler} variant="outlined">
          Import
        </Button>
      </div>
    </div>
  );
}

export default ReportPageInfo;
