import React, { useContext, useState, useEffect } from 'react';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

import { useTheme } from '@emotion/react';

import DropHere from './DropHere';

import RemoveMetricButton from './RemoveMetricButton';

import Grouping from './Grouping';

function AxisMenuMetric({ widget, metricObj }) {
  const theme = useTheme();

  const {
    currentlyDraggingWidget,
    setCurrentlyDraggingWidget,
    isAxisMenuOpen,
    toggleIsAxisSettingsOpen,
    updateWidget
  } = useContext(ReportBuilderContext);

  let showDropZone = false;

  if (['dimensions'].includes(currentlyDraggingWidget?.category)) {
    showDropZone = true;
  } else {
    showDropZone = false;
  }
  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const dropOnWidget = (e, value, index) => {
    console.log('AxisMenuMetric>onDrop: ');
    // e.preventDefault();
    // e.stopPropagation();
    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (isFileTransfer) {
      e.preventDefault();
      return;
    }

    // setIsDraggingWidget(false);

    const droppedItemString = e.dataTransfer.getData('droppedItem');

    const droppedItem = JSON.parse(droppedItemString);

    if (droppedItem.value.category === 'dimensions') {
      e.preventDefault();

      e.stopPropagation();
    } else {
      e.preventDefault();
      //   e.stopPropagation();
      return;
    }

    console.log('AxisMenuMetric>onDrop>value: ', value);
    // let copyOfWidgets = [...widgets];
    // const copyOfDroppedOnWidget = { ...widgets[index] };

    let indexToSplice = index;

    switch (true) {
      case droppedItem.value.category === 'dimensions':
        //   &&
        //     value.category === 'widgets':
        // copyOfWidgets[index] = {
        //   ...copyOfDroppedOnWidget,
        //   dimensions: droppedItem.value.dimensions
        // };
        // setWidgets([...copyOfWidgets]);

        const copyOfMetrics = [
          ...widget.metrics.map((metricObj) =>
            JSON.parse(JSON.stringify(metricObj))
          )
        ];
        console.log('metricObj._id: ', metricObj._id);

        console.log('copyOfMetrics: ', copyOfMetrics);
        const metricObjIndex = copyOfMetrics.findIndex(
          (_metricObj) => String(_metricObj._id) === String(value._id)
        );
        console.log('metricObjIndex: ', metricObjIndex);

        copyOfMetrics[metricObjIndex] = {
          ...metricObj,
          dimensions: [...droppedItem?.value?.dimensions]
        };

        // const copyOfWidget = {...widget, widget.metric};
        updateWidget({ ...widget, metrics: copyOfMetrics }, index);
        break;

      default:
        return;
    }

    const droppedItemElement = document.getElementById(droppedItem);
  };

  return (
    <div
      onDrop={(e) => dropOnWidget(e, metricObj)}
      onDragOver={(e) => dragOver(e)}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // width: 'auto',
        // minWidth: 'auto',

        fontSize: '16px',
        // border: '1px solid rgba(150,150,150,0.5)',
        borderRadius: '5px',
        background:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(to top, rgb(70, 70, 81), rgba(85, 85, 100))'
            : 'white',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      }}
    >
      <div style={{ padding: '10px', fontWeight: 'bold' }}>
        {metricObj.metric}
      </div>
      <div>
        <Grouping widget={widget} metricObj={metricObj} />
        <RemoveMetricButton widget={widget} metric={metricObj?.metric} />
      </div>

      {/* {showDropZone && <DropHere widget={widget} isBlurOff={true} />} */}
    </div>
  );
}

export default AxisMenuMetric;
