import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { SnackbarProvider, useSnackbar } from 'notistack';

function ImageWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler,
  // widgetType,
  dropOnWidget
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  // const logoUrl =
  //   currentManagerAccount?.logo ||
  //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  // console.log('logoUrl: ', logoUrl);

  const [imageUrl, setImageUrl] = useState();

  const [dragActive, setDragActive] = React.useState(false);

  // useEffect(() => {
  //   console.log(`widgetId: ${widget.id}, imageUrl: ', ${imageUrl}`);
  // }, [imageUrl]);

  const [imgNaturalHeight, setImgNaturalHeight] = useState();
  const [imgNaturalWidth, setImgNaturalWidth] = useState();
  useEffect(() => {
    // if (!imageUrl && widget?.imageUrl) {
    //   // setImageUrl(widget.imageUrl);
    // }
    setImageUrl(widget.imageUrl);
  }, [widget.imageUrl]);

  const onDropHandler = async (e) => {
    // console.log('widget12312312: ', widget);
    e.preventDefault();

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    const file = e?.dataTransfer?.files[0];
    console.log('file: ', file);

    if (
      isFileTransfer &&
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    ) {
      enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
        variant: 'error'
      });
      return;
    }
    if (file) {
      e.stopPropagation();

      const copyOfWidget = { ...widget };

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function () {
        console.log('img.naturalHeight: ', img.naturalHeight);
        setImgNaturalHeight(this.naturalHeight);
        setImgNaturalWidth(this.naturalWidth);

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        console.log('dataURL: ', dataUri);

        copyOfWidget.imageDataUri = dataUri;
        copyOfWidget.imageOriginalAspectRatio =
          img.naturalWidth / img.naturalHeight;
      };

      // const files = e.dataTransfer.files;
      // console.log('files: ', files);
      // return;

      // for (let file of files) {
      let reader = new FileReader();

      reader.onload = async () => {
        // console.log('reader.result: ', reader.result);
        const binaryStr = reader.result;

        // setImageUrl(binaryStr);
        updateWidget(copyOfWidget, widgetIndex);

        setDragActive(false);

        // console.log('binaryStr: ', binaryStr);
      };

      reader.readAsDataURL(file);

      // const image = reader.src;

      // if (image.src) {
      //   setImageUrl(image.src);
      // }
      // }

      // handleFiles(e.dataTransfer.files);
    }
    // e.stopPropagation();
    // dropOnWidget(e, widget, widgetIndex);
  };

  // console.log('imgNaturalHeight: ', imgNaturalHeight);

  // const [height, setHeight] = useState(undefined);
  // const [width, setWidth] = useState(undefined);

  // useEffect(() => {
  //   if (height) {
  //   }
  // }, [widget.height, widget.width]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e12333:', e);

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (!isFileTransfer) {
      return;
    }
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // console.log('widget: ', widget);
  return (
    <div
      key={widget.id}
      onDrop={(e) => {
        // console.log('widget12312312: ', widget);
        e.preventDefault();
        // e.stopPropagation();
        // dropOnWidget(e, widget, widgetIndex);
      }}
      onMouseUpHandler={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {dragActive ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(e) => onDropHandler(e)}
        >
          <div
            style={{
              height: '70%',
              width: '70%',
              minWidth: '100px',
              minHeight: '50px',
              border: '1px dashed lightgrey',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px'
            }}
          >
            DROP IMAGE HERE <FileUploadIcon />
          </div>
        </div>
      ) : (
        <div
          onDragEnter={handleDrag}
          onDrop={(e) => onDropHandler(e)}
          draggable={false}
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            // border-radius: 50%;
            // border: solid 1px #e6e6e6;
            margin: '0 auto',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            // img: {
            //   height: '100%',
            //   width: '100%'
            // }
            height: '100%',
            backgroundImage: `url(${widget.imageDataUri || imageUrl})`
          }}
        >
          {/* <img
            onDragEnter={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            draggable={false}
            src={imageUrl}
            alt="Logo"
            style={{
              borderRadius: '5px',
              // display: 'block'
              // height: '100%',
              height: imgNaturalHeight,
              width: imgNaturalWidth
              // objectFit: 'cover',
              // overflow: 'hidden'
              // opacity: 0.5
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          /> */}
        </div>
      )}
    </div>
  );
}

export default React.memo(ImageWidget);
