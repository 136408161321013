import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

function TextWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const theme = useTheme();
  const [textInput, setTextInput] = useState('');

  const [valueIsEdited, setValueIsEdited] = useState(false);
  const [edit, toggleEdit] = useState(false);

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }
    setTextInput(widget.value);
  }, [widget.value]);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  // const onClickHandler = (e) => {
  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }
  //   console.log('isRightMB: ', isRightMB);
  // };

  const onSaveHandler = () => {
    updateWidget({ ...widget, value: textInput }, widgetIndex);
    // setTextInput('');

    if (!textInput) {
      setValueIsEdited(false);
    }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.value);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget(
        {
          ...widget,
          value: textInput
          // || originalWidgetCopy.value
        },
        widgetIndex
      );
      // setTextInput('');

      if (!textInput) {
        setValueIsEdited(false);
      }

      toggleEdit(false);
      return;
    }
  };
  return (
    <div
      onMouseUpHandler={onMouseUpHandler}
      onClick={() => {
        toggleEdit(true);
      }}
      onBlur={(e) => onSaveHandler(e)}
    >
      {edit ? (
        <textarea
          autoFocus
          style={{
            resize: 'none',
            padding: '10px',
            fontFamily: 'Roboto, Raleway, sans-serif',
            lineHeight: '1.5',
            fontSize: '12px',
            letterSpacing: '0.2px',
            color: 'black',
            // width: textInput.length * 1 + 'ch',
            // width:
            //   numUpper * 0.35 + numLarge * 1 + textInput.length * 0.925 + 'ch',
            // minWidth: '1ch',
            width: widget?.width,
            height: widget?.height,
            outline: 'none',
            border: 'none'
            // fontSize: '11.5px'
          }}
          value={textInput}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Tooltip
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                maxWidth: '200px'
                // padding: '2px'
              }}
            >
              {/* <span style={{ width: '100%', textAlign: 'center' }}>
                {widget.type === 'FREETEXT'
                  ? '* Free text *'
                  : '* Static text *'}
              </span> */}

              <span
                style={{
                  maxWidth: '190px',
                  whiteSpace: 'pre-line'
                  // paddingRight: '10px'
                  // padding: '5px'
                }}
              >
                {widget.description}
              </span>
            </div>
          }
        >
          <div
            style={{
              padding: '10px',
              // width: '100%',
              // lineBreak: 'anywhere',
              width: widget?.width,
              height: widget?.height,
              maxWidth: widget?.width,
              maxHeight: widget?.height,
              //   background: 'red',
              // top: timebox?.duration <= 5 && '-5px',
              // position: timebox?.duration <= 5 && 'relative',
              // lineHeight: timebox?.duration <= 5 && '3px',
              // padding:
              //   timebox?.duration <= 10 ? '3px 9px 0px 9px' : '9px 9px 3px 9px',
              whiteSpace: 'pre-wrap',
              // fontSize: '14px',
              overflowY: 'scroll'
            }}
            // onMouseDown={() => setIsDraggingWidget(true)}
            // onMouseUp={() => setIsDraggingWidget(false)}
          >
            {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
            {widget.value}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default TextWidget;
