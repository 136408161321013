import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import BalanceIcon from '@mui/icons-material/Balance';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import ReportList from './ReportList';
import ReportPages from './ReportPages';
import ReportPageInfo from './ReportPageInfo';

// import './kanban.css';

function ViewDialogContent({ isExpanded, displayView, setDisplayView }) {
  const [selectedReport, setSelectedReport] = useState();
  const [selectedReportPage, setSelectedReportPage] = useState();

  return (
    <div
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '50px',
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
          // backgroundColor:
          //   stylesStoredInRedux.styleName === 'light'
          //     ? 'red'
          //     : 'rgba(255,255,255,0)'
        }}
      ></div>
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'row',
          // padding: '10px 20px',
          // overflowX: 'scroll',
          overflowX: 'scroll',
          height: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          maxHeight: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          width: isExpanded ? 'calc(100vw - 116px)' : 'calc(90vw - 116px)',
          maxWidth: isExpanded ? 'calc(100vw - 116px)' : 'calc(90vw - 116px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <ReportList
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
          />
          <ReportPages
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            selectedReportPage={selectedReportPage}
            setSelectedReportPage={setSelectedReportPage}
          />
          <ReportPageInfo
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            selectedReportPage={selectedReportPage}
            setSelectedReportPage={setSelectedReportPage}
          />
        </div>
      </div>
    </div>
  );
}

// export default Kanban;

ViewDialogContent.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(ViewDialogContent);
