import React, { useEffect, useState, useContext } from 'react';

import { AccountContext } from '../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { TextField } from '@mui/material';
import { set } from 'mongoose';

import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import PieChartIcon from '@mui/icons-material/PieChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TableChartIcon from '@mui/icons-material/TableChart';

import ImageIcon from '@mui/icons-material/Image';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import FiveKIcon from '@mui/icons-material/FiveK';

import ClearAllIcon from '@mui/icons-material/ClearAll';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import { useTheme } from '@mui/material';
import { phoneMockupImage } from './assets/phoneMockupImage';

function TaxonomyWidgetList({ isDraggingWidget, setIsDraggingWidget, zoom }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { currentlyDraggingWidget, setCurrentlyDraggingWidget } =
    useContext(ReportBuilderContext);
  const theme = useTheme();
  const [isDraggingThisWidgetType, setIsDraggingThisWidgetType] =
    useState(false);
  const [search, setSearch] = React.useState('');

  // const [grabbing, toggleGrabbing] = useState(false);

  const widgets = [
    // { name: '-', type: 'STRING', value: '-', defaultValue: '' },
    // { name: '_', type: 'STRING', value: '_', defaultValue: '' },
    // { name: '|', type: 'STRING', value: '|', defaultValue: '' },
    // { name: '#', type: 'STRING', value: '#', defaultValue: '' },
    // { name: '(', type: 'STRING', value: '(', defaultValue: '' },
    // { name: ')', type: 'STRING', value: ')', defaultValue: '' },
    // { name: '[', type: 'STRING', value: '[', defaultValue: '' },
    // { name: ']', type: 'STRING', value: ']', defaultValue: '' },
    // { name: '/', type: 'STRING', value: '/', defaultValue: '' },
    {
      // isResizable: false,
      category: 'tools',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Erase All',
      type: 'ERASE_ALL_TOOL'
      // imageUrl:
      //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
      // // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      // style: {
      //   // height: '200px',
      //   // width: '200px'
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   // opacity: 1,
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // }
      // isShowingCard: true
      // defaultValue: ''
    },
    // {
    //   // isResizable: false,
    //   category: 'tools',
    //   title: '',
    //   description: '',
    //   dimensions: [],
    //   metrics: [],
    //   name: 'Freeze Data',
    //   type: 'FREEZE_DATA_TOOL',
    //   // imageUrl:
    //   //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
    //   // // value:
    //   //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   style: {
    //     // height: '200px',
    //     // width: '200px'
    //     // overflow: 'hidden',
    //     // padding: '10px'
    //     // opacity: 1,
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // isShowingCard: true
    //   // defaultValue: ''
    // },
    {
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Image',
      type: 'IMAGE_WIDGET',
      imageUrl:
        'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: '200px',
      width: '200px',
      // overflow: 'hidden',
      // padding: '10px'
      opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '200px',
      //   width: '200px',
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   opacity: 1,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isAspectRatioLocked: true,
      originalAspectRatio: 711 / 345,
      originalHeight: 711,
      originalWidth: 345,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Phone Mockup',
      type: 'MOCKUP_WIDGET',
      mockupImageDataUri: phoneMockupImage,
      // mockupUrl:
      //   // 'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup.png',
      //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup-safe.png',

      // imageUrl: undefined,
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: '711px',
      width: '345px',
      opacity: 1,
      // overflow: 'hidden',
      // padding: '10px'
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '711px',
      //   width: '345px',
      //   opacity: 1,
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    // {
    //   isResizable: true,
    //   category: 'widgets',
    //   title: '',
    //   description: '',
    //   dimensions: [],
    //   metrics: [],
    //   name: 'Manager Account Logo',
    //   type: 'MANAGER_ACCOUNT_LOGO_WIDGET',
    //   // value:
    //   //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   height: '80px',
    //   width: '200px',
    //   padding: '10px',
    //   opacity: 1,
    //   // style: {
    //   //   height: '80px',
    //   //   width: '200px',
    //   //   padding: '10px',
    //   //   opacity: 1
    //   //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   // },
    //   imageUrl:
    //     currentManagerAccount?.logo ||
    //     'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png',
    //   isShowingCard: false
    //   // defaultValue: ''
    // },
    {
      isResizable: false,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Date Picker',
      type: 'DATE_PICKER_WIDGET',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: '60px',
      width: '200px',
      padding: '10px',
      opacity: 1,
      // style: {
      //   height: '60px',
      //   width: '200px',
      //   padding: '10px',
      //   opacity: 1
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Text',
      type: 'TEXT_WIDGET',
      value:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: '100px',
      width: '200px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '100px',
      //   width: '200px',
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: [],
      function: 'sum',
      metrics: [
        {
          metric: 'cost',
          axis: 'leftAxisId'
        }
      ],
      name: 'Number',
      type: 'NUMBER_WIDGET',
      value: '',
      variant: 'STANDARD',
      height: '150px',
      width: '150px',
      opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '150px',
      //   width: '150px',
      //   opacity: 1,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    // {
    //   isResizable: true,
    //   category: 'widgets',
    //   title: 'Cost',
    //   description: '',
    //   dimensions: [],
    //   function: 'sum',
    //   metrics: [{ metric: 'cost', axis: 'leftAxisId' }],
    //   interval: 'date',
    //   name: 'Line Chart',
    //   type: 'LINE_CHART_WIDGET',

    //   style: {
    //     height: '310px',
    //     width: '630px',
    //     opacity: 1,
    //     boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   },
    //   isShowingCard: true,
    //   data: []
    //   // defaultValue: ''
    // },
    {
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: [],
      function: 'sum',
      metrics: [
        {
          metric: 'cost',
          axis: 'leftAxisId',
          grouping: 'standard',
          chartType: 'bar'
        }
      ],
      interval: 'date',
      name: 'Bar Chart',
      type: 'BAR_CHART_WIDGET',

      height: '310px',
      width: '630px',
      opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '310px',
      //   width: '630px',
      //   opacity: 1,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true,
      data: []
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: ['documentType'],
      function: 'sum',
      metrics: [{ metric: 'cost', axis: 'leftAxisId' }],
      name: 'Pie Chart',
      type: 'PIE_CHART_WIDGET',

      height: '310px',
      width: '470px',
      opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '310px',
      //   width: '470px',
      //   opacity: 1,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    // {
    //   isResizable: true,
    //   category: 'widgets',
    //   title: 'Cost',
    //   description: '',
    //   dimensions: ['documentType'],
    //   function: 'sum',
    //   metrics: [{ metric: 'cost', axis: 'leftAxisId' }],
    //   name: 'Table Chart',
    //   type: 'TABLE_CHART_WIDGET',

    //   style: {
    //     height: '310px',
    //     width: '470px',
    //     opacity: 1,
    //     boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   },
    //   isShowingCard: true
    //   // defaultValue: ''
    // },
    {
      isResizable: true,
      category: 'dimensions',
      title: 'None',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'None',
      type: 'DIMENSION',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'dimensions',
      title: 'Name',
      description: '',
      dimensions: ['name'],
      metrics: [],
      name: 'Name',
      type: 'DIMENSION',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'functions',
      title: 'Average',
      description: '',
      function: 'average',
      metrics: [],
      name: 'AVERAGE',
      type: 'FUNCTION',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'functions',
      title: 'SUM',
      description: '',
      function: 'sum',
      metrics: [],
      name: 'SUM',
      type: 'FUNCTION',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'intervals',
      title: 'Date',
      description: '',
      interval: 'date',
      metrics: [],
      name: 'Date',
      type: 'INTERVAL',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'intervals',
      title: 'Week',
      description: '',
      interval: 'week',
      metrics: [],
      name: 'Week',
      type: 'INTERVAL',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'intervals',
      title: 'Month',
      description: '',
      interval: 'month',
      metrics: [],
      name: 'Month',
      type: 'INTERVAL',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'intervals',
      title: 'Day of Month',
      description: '',
      interval: 'dayOfMonth',
      metrics: [],
      name: 'Day of Month',
      type: 'INTERVAL',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'intervals',
      title: 'Day of Week',
      description: '',
      interval: 'dayOfWeek',
      metrics: [],
      name: 'Day of Week',
      type: 'INTERVAL',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'dimensions',
      title: 'Data Source',
      description: '',
      dimensions: ['documentType'],
      metrics: [],
      name: 'Data Source',
      type: 'DIMENSION',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    },
    ...[
      'impressions',
      'cpm',
      'clicks',
      // 'ctr',
      'cpcv',
      'cpc',
      'cost',
      'budget',
      'action01Count',
      'action01Cpa',
      'action01Value',
      'action01Roas'
    ].map((column) => ({
      isResizable: false,
      category: 'metrics',
      title: column?.[0]?.toUpperCase() + column?.slice(1),
      description: '',
      dimensions: [],
      metrics: [
        {
          metric: column,
          axis: 'leftAxisId',
          grouping: 'standard',
          chartType: 'line'
        }
      ],
      name: column?.[0]?.toUpperCase() + column?.slice(1),
      type: 'METRIC',

      // style: {
      //   // height: '250px',
      //   // width: '400px',
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true
      // defaultValue: ''
    })),
    {
      isResizable: false,
      category: 'metrics',
      title: 'Ctr (%)',
      description: '',
      dimensions: [],
      metrics: [
        {
          metric: 'ctr',
          axis: 'leftAxisId',
          grouping: 'standard',
          chartType: 'line'
        }
      ],
      name: 'Ctr (%)',
      type: 'METRIC',

      isShowingCard: true
      // defaultValue: ''
    }
    // {
    //   isResizable: true,
    //   category: 'metrics',
    //   title: 'Impressions',
    //   description: '',
    //   dimensions: [],
    //   metrics: ['impressions'],
    //   name: 'Impressions',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // },
    // {
    //   isResizable: true,
    //   category: 'metrics',
    //   title: 'Clicks',
    //   description: '',
    //   dimensions: [],
    //   metrics: ['clicks'],
    //   name: 'clicks',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // },
    // {
    //   isResizable: true,
    //   category: 'metrics',
    //   title: 'Budget',
    //   description: '',
    //   dimensions: [],
    //   metrics: ['budget'],
    //   name: 'budget',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // }
    // {
    //   name: 'Static text',
    //   type: 'STATICTEXT',
    //   value: 'STATIC TEXT'
    //   // defaultValue: ''
    // },

    // {
    //   name: 'List',
    //   type: 'LIST',
    //   value: 'LIST',
    //   // defaultValue: '',
    //   options: []
    // },
    // // {
    // //   name: 'Dynamic List',
    // //   type: 'DYNAMICLIST',
    // //   value: 'DYNAMIC LIST',
    // //   // defaultValue: '',
    // //   options: []
    // // },
    // {
    //   name: 'Year YYYY',
    //   type: 'YEARYYYY',
    //   value: () => new Date().getFullYear()
    //   // defaultValue: ''
    // },

    // {
    //   name: 'Year YY',
    //   type: 'YEARYY',
    //   value: () => String(String(new Date().getFullYear()).slice(2))
    //   // defaultValue: ''
    // },
    // // {
    // //   name: 'Month M',
    // //   type: 'THISMONTH',
    // //   value: () => new Date().getMonth() + 1
    // //   // defaultValue: ''
    // // },
    // {
    //   name: 'Month MM',
    //   type: 'MONTHMM',
    //   value: () =>
    //     (new Date().getMonth() + 1).length !== 2
    //       ? '0' + (new Date().getMonth() + 1)
    //       : new Date().getMonth() + 1
    //   // defaultValue: ''
    // },
    // // {
    // //   name: 'Today D',
    // //   type: 'TODAY',
    // //   value: new Date().getDate()
    // //   // defaultValue: ''
    // // },
    // {
    //   name: 'Day DD',
    //   type: 'DAYDD',
    //   value: () =>
    //     new Date().getDate().toString().length !== 2
    //       ? '0' + new Date().getDate()
    //       : new Date().getDate()
    //   // defaultValue: ''
    // }
  ];

  //   const dragStart = (e, value) => {
  //     const target = e.target;
  //     console.log('value: ', value);
  //     e.dataTransfer.setData('droppedItem', JSON.stringify(value));
  //     setTimeout(() => {
  //       target.style.display = 'none';
  //     }, 0);
  //   };

  const dragStart = (e, value, origin, originIndex) => {
    setIsDraggingThisWidgetType(value.type);
    setIsDraggingWidget(true);
    setCurrentlyDraggingWidget(value);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        value,
        origin,
        originIndex,
        eNativeEventOffsetX: e.nativeEvent.offsetX,
        eNativeEventOffsetY: e.nativeEvent.offsetY
      })
    );
    console.log('{ value, origin, originIndex }: ', {
      value,
      origin,
      originIndex
    });

    switch (true) {
      // case 'TEXT':

      // case 'LINE_CHART':

      case value.category === 'widgets':
        target.style.width = value.width;
        target.style.minWidth = value.width;
        target.style.height = value.height;
        target.style.minHeight = value.height;
        target.style.zoom = zoom;
        target.style.display = 'flex';
        target.style.justifyContent = 'center';
        target.style.alignItems = 'center';
        break;

      default:

      // target.style.transform = `scale(${transformScale})`;

      // target.style.transformOrigin = '0% 0%';
      // target.style.height = value.style.height;
      // target.style.height = '100%';

      // var elem = window.document.createElement('div');
      // elem.style.width = '100px';
      // elem.style.height = '100px';
      // elem.style.backgroundColor = 'green';
      // target.appendChild(elem);
    }

    setTimeout(() => {
      target.style.display = 'none';
      target.style.zoom = 1;
    }, 0);
  };

  const dragEnd = (e, value) => {
    setIsDraggingThisWidgetType(null);
    setIsDraggingWidget(false);
    setCurrentlyDraggingWidget(null);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
      target.style.width = 'auto';
      target.style.minWidth = 'auto';
      target.style.height = 'auto';
      target.style.minHeight = 'auto';
    }, 0);
    // toggleGrabbing(false);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [filteredWidgetList, setFilteredWidgetList] = useState([]);
  useEffect(() => {
    const newWidgetList = widgets.filter(
      (widget) =>
        widget.type.includes(search.toLocaleUpperCase()) ||
        (widget.type === 'METRIC' &&
          widget?.metrics?.filter((metric) => metric?.includes(search))
            ?.length) ||
        (widget.type === 'DIMENSION' &&
          widget?.dimensions?.filter((dimensions) =>
            dimensions?.includes(search)
          )?.length)
    );
    setFilteredWidgetList(newWidgetList);
  });

  const iconStyle = {
    color: theme.palette.mode === 'light' && 'grey',
    fontSize: '20px',
    marginRight: '7px'
  };
  return (
    <div style={{}}>
      <div
        style={{
          padding: '0 0 10px 10px'
          // background: 'rgba(255,255,255,0.6)'
        }}
      >
        <TextField
          size="small"
          variant="standard"
          id="outlined-controlled"
          label="Search"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          //   height: '100%',
          // background: 'rgba(255,255,255,0.6)',
          padding: '10px',
          rowGap: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          //   maxHeight: 'calc(71vh - 64px)',
          overflowY: 'scroll',
          // height: '100%'
          height: 'calc(80vh - 78px)'
          // height: '80vh'
          //   maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Tools
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type.includes('TOOL'))
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'ERASE_ALL_TOOL':
                icon = <ClearAllIcon style={iconStyle} />;
                break;
              case 'FREEZE_DATA_TOOL':
                icon = <AcUnitIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  // background: 'white',
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>
              </div>
            );
          })}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Widgets
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type.includes('WIDGET'))
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'IMAGE_WIDGET':
                icon = <ImageIcon style={iconStyle} />;
                break;

              case 'MOCKUP_WIDGET':
                icon = <SmartphoneIcon style={iconStyle} />;
                break;
              case 'MANAGER_ACCOUNT_LOGO_WIDGET':
                icon = <BrandingWatermarkIcon style={iconStyle} />;
                break;

              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'BAR_CHART_WIDGET':
                icon = <BarChartIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  // background: 'white',
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>
              </div>
            );
          })}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Dimensions
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'DIMENSION')
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>
              </div>
            );
          })}
        {/* <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Functions
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'FUNCTION')
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>
              </div>
            );
          })} */}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Intervals
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'INTERVAL')
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}{' '}
                  {['dayOfWeek', 'dayOfMonth'].includes(widget.interval) ? (
                    <span style={{ fontSize: '10px' }}>(avg)</span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            );
          })}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Metrics
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'METRIC')
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;

              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>{' '}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TaxonomyWidgetList;
