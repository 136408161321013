import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';

import { InterfaceContext } from '../../../context/InterfaceContext';
import { AccountContext } from '../../../context/AccountContext';

import Tooltip from '@mui/material/Tooltip';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

import SaveAsIcon from '@mui/icons-material/SaveAs';

import TextField from '@mui/material/TextField';

import { createReportPageTemplatesMutation } from './../../../graphql/queries';

import { DataContext } from '../../../context/DataContext';

import { useMutation } from '@apollo/client';

import { useSnackbar } from 'notistack';

import { ReportBuilderContext } from '../../../context/ReportBuilderContext';

import { createThumbnail } from './report-builder-list/createThumbnail';

import SelectAccountMenu from './utils/SelectAccountMenu';

import { v4 as uuidv4 } from 'uuid';

export default function BasicPopover({ zoom, setZoom }) {
  const { enqueueSnackbar } = useSnackbar();

  const [isAllowingManagerAccountAccess, setIsAllowingManagerAccountAccess] =
    useState(false);

  const [isAllowingAccountAccess, setIsAllowingAccountAccess] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const { currentManagerAccount } = useContext(AccountContext);
  const { listOfData } = useContext(DataContext);

  const { reportPage } = useContext(ReportBuilderContext);

  const [name, setName] = React.useState('');
  // const { daysToGraph, setDaysToGraph } = useContext(InterfaceContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createReportPageTemplates, { called }] = useMutation(
    createReportPageTemplatesMutation
  );

  const handleSave = async () => {
    if (
      !isAllowingManagerAccountAccess &&
      (!isAllowingAccountAccess || !selectedAccountIds?.length)
    ) {
      enqueueSnackbar('Please choose access', {
        variant: 'error'
      });
      return;
    }
    // let accountIds = listOfData.map((obj) => obj.accountId);

    // accountIds = [...new Set(accountIds)];

    const copyOfReportPage = { ...reportPage };

    // const thumbnail = await createThumbnail('report-builder-page', 0, 5);
    copyOfReportPage.thumbnailImageDataUri = await createThumbnail(
      'report-builder-page',
      0.3
    );
    copyOfReportPage.previewImageDataUri = await createThumbnail(
      'report-builder-page',
      0.7
    );
    // ######## change id ############

    // copyOfReportPage.id = copyOfReportPage.id;
    // delete copyOfReportPage.id;

    const copyOfWidgets = [
      ...copyOfReportPage.widgets.map((widget) => {
        const copyOfWidget = {
          ...widget,
          metrics: widget.metrics.map((metricObj) => {
            const copyOfMetricObj = { ...metricObj };
            // copyOfMetricObj.id = copyOfMetricObj._id;
            // delete copyOfMetricObj._id;
            return copyOfMetricObj;
          })
        };

        copyOfWidget.id = uuidv4();

        delete copyOfWidget._id;
        delete copyOfWidget.data;
        delete copyOfWidget.dates;

        delete copyOfWidget.imageDataUri;
        delete copyOfWidget.imageUrl;
        delete copyOfWidget.mockupImageDataUri;
        delete copyOfWidget.imageOriginalAspectRatio;
        delete copyOfWidget.naturalHeight;
        delete copyOfWidget.naturalWidth;
        delete copyOfWidget.screenImageDataUri;

        return copyOfWidget;
      })
    ];

    copyOfReportPage.widgets = copyOfWidgets;
    copyOfReportPage.name = name;
    delete copyOfReportPage.isThumbnailOutOfDate;
    delete copyOfReportPage._id;

    const input = {
      // managerAccountId: currentManagerAccount._id,
      // name,
      managerAccountId: currentManagerAccount._id,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      reportPages: [copyOfReportPage]
      // action: String,
      // updateKeys: [String],
    };

    // console.log('SaveTemplatePopper>input: ', input);

    const res = await createReportPageTemplates({
      variables: { input }
    });

    if (res?.data?.createReportPageTemplates?.response === 'success') {
      enqueueSnackbar('Template created', {
        variant: 'success'
      });
      setIsAllowingManagerAccountAccess(false);
      setIsAllowingAccountAccess(false);
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title="Save as template">
        <Button
          aria-describedby={id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '25px',
            width: '25px',
            minHeight: '25px',
            height: '25px'
            // padding: '10px'
          }}
          onClick={handleClick}
        >
          <SaveAsIcon style={{ fontSize: '16px' }} />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            padding: '20px 20px 5px 20px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          Save as template
        </div>
        <div
          style={{
            padding: '0px 20px 20px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: '10px'
          }}
        >
          {/* <div
            style={{ width: '160px', fontSize: '12px', textAlign: 'center' }}
          >
            The number of days back NexusTable will visualize graphs. NexusTable
            runs slower the higher number you enter.
          </div> */}
          {/* {value < 15 && (
            <div
              style={{
                width: '160px',
                fontSize: '12px',
                textAlign: 'center',
                color: value < 15 && 'rgb(255, 107, 107)'
              }}
            >
              If you have lower than 15 days the orange performance warnings
              won't work.
            </div>
          )} */}

          <div style={{ marginBottom: '10px' }}>
            <TextField
              style={{ width: '290px' }}
              size="small"
              variant="standard"
              id="outlined-controlled"
              label="Template Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div style={{ width: '300px' }}>
            <div>Access:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingManagerAccountAccess}
                  onChange={() =>
                    setIsAllowingManagerAccountAccess(
                      !isAllowingManagerAccountAccess
                    )
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div>Current Manager Account</div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingAccountAccess}
                  onChange={() =>
                    setIsAllowingAccountAccess(!isAllowingAccountAccess)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div>
                <SelectAccountMenu
                  isDisabled={!isAllowingAccountAccess}
                  selectedAccountIds={selectedAccountIds}
                  setSelectedAccountIds={setSelectedAccountIds}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '10px',
              width: '100%',
              justifyContent: 'end'
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setAnchorEl(null);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => {
                // setAnchorEl(null);
                handleSave();
              }}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
