import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';

// import PeopleIcon from '@mui/icons-material/People';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';

import TextField from '@mui/material/TextField';

// import AddMenu from './add-account/AddMenu';

import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { dataSourcesQuery } from '../../../../graphql/queries';

import AddDataSourceModal from './add-datasource/AddDataSourceModal';

// import AddUserButton from './invite-user/AddUserButton';
import AddDataSourceButton from './add-datasource/AddDataSourceButton2';

import AddAdformDataSourceModal from './add-datasource/add-data-sources-modals/adform/AddAdformDataSourceModal';

import AddGoogleAdsDataSourceModal from './add-datasource/add-data-sources-modals/google-ads/AddGoogleAdsDataSourceModal';
import AddGoogleAnalytics4DataSourceModal from './add-datasource/add-data-sources-modals/google-analytics/AddGoogleAnalytics4DataSourceModal';
import AddFacebookAdsDataSourceModal from './add-datasource/add-data-sources-modals/facebook-ads/AddFacebookAdsDataSourceModal';
import AddSnapchatAdsDataSourceModal from './add-datasource/add-data-sources-modals/snapchat-ads/AddSnapchatAdsDataSourceModal';
import AddTikTokAdsDataSourceModal from './add-datasource/add-data-sources-modals/tiktok-ads/AddTikTokAdsDataSourceModal';

import AddPinterestAdsDataSourceModal from './add-datasource/add-data-sources-modals/pinterest-ads/AddPinterestAdsDataSourceModal';
import AddAmazonAdsDataSourceModal from './add-datasource/add-data-sources-modals/amazon-ads/AddAmazonAdsDataSourceModal';
import AddReadpeakDataSourceModal from './add-datasource/add-data-sources-modals/readpeak/AddReadpeakDataSourceModal';

import AddStrossleDataSourceModal from './add-datasource/add-data-sources-modals/strossle/AddStrossleDataSourceModal';

import AddAppleSearchAdsDataSourceModal from './add-datasource/add-data-sources-modals/ZZZ-apple-search-ads/AddAppleSearchAdsDataSourceModal';

import AddTaboolaAdsDataSourceModal from './add-datasource/add-data-sources-modals/taboola-ads/AddTaboolaAdsDataSourceModal';

import AddLinkedInAdsDataSourceModal from './add-datasource/add-data-sources-modals/linkedin-ads/AddLinkedInAdsDataSourceModal';
import AddMicrosoftAdvertisingDataSourceModal from './add-datasource/add-data-sources-modals/microsoft-advertising/AddMicrosoftAdvertisingDataSourceModal';

import AddCM360DataSourceModal from './add-datasource/add-data-sources-modals/cm360/AddCM360DataSourceModal';

import AddDV360DataSourceModal from './add-datasource/add-data-sources-modals/dv360/AddDv360DataSourceModal';

import AdformTermsModal from './add-datasource/adformButton/AdformTermsModal';

import FacebookAdsTermsModal from './add-datasource/facebookAdsButton/FacebookAdsTermsModal';

import SnapchatAdsTermsModal from './add-datasource/snapchatAdsButton/SnapchatAdsTermsModal';

import TikTokAdsTermsModal from './add-datasource/tikTokAdsButton/TikTokAdsTermsModal';

import PinterestAdsTermsModal from './add-datasource/pinterestAdsButton/PinterestAdsTermsModal';

import AmazonAdsTermsModal from './add-datasource/amazonAdsButton/AmazonAdsTermsModal';

import ReadpeakTermsModal from './add-datasource/readpeakButton/ReadpeakTermsModal';

import StrossleTermsModal from './add-datasource/strossleButton/StrossleTermsModal';

import AppleSearchAdsTermsModal from './add-datasource/appleSearchAdsButton/AppleSearchAdsTermsModal';

import MicrosoftInvestTermsModal from './add-datasource/microsoftInvestButton/MicrosoftInvestTermsModal';

import TaboolaAdsTermsModal from './add-datasource/taboolaAdsButton/TaboolaAdsTermsModal';

import LinkedInAdsTermsModal from './add-datasource/linkedInAdsButton/LinkedInAdsTermsModal';

import MicrosoftAdvertisingTermsModal from './add-datasource/microsoftAdvertisingButton/MicrosoftAdvertisingTermsModal';

import GoogleTermsModal from './add-datasource/components/googleTermsModal/GoogleTermsModal';

import CustomDataSourceTermsModal from './add-datasource/customDataSourceButton/CustomDataSourceTermsModal';

import { compareDesc } from 'date-fns/esm';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountContext } from '../../../../context/AccountContext';

function DataSourcelist({
  selectedDataSourceId,
  setSelectedDataSourceId,
  code,
  setCode,
  dataSource,
  setDataSource,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [skip, toggleSkip] = useState('false');
  const [filterSearch, setFilterSearch] = useState('');

  const variables = {
    dataSourcesInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id
    }
  };

  const [loadDataSources, { called, loading, data, refetch }] = useLazyQuery(
    dataSourcesQuery,
    {
      variables
    }
  );

  useEffect(() => {
    if (currentManagerAccount?._id) {
      loadDataSources();
    }
  }, [currentManagerAccount]);

  // const { data, refetch } = useQuery(dataSourcesQuery, {
  //   variables,
  //   fetchPolicy: 'no-cache',
  //   skip: false
  // });

  // useEffect(() => {
  //   if (data) {
  //     toggleSkip(true);
  //   }
  // }, [data]);

  let listOfDataSources = data && data.dataSources ? data.dataSources : [];

  //   const filterField = "Al"

  listOfDataSources = listOfDataSources.filter((obj) => {
    if (
      (obj.name &&
        obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
      (obj.dataSourceRef &&
        obj.dataSourceRef.toLowerCase().includes(filterSearch.toLowerCase())) ||
      (obj.dataSourceAccountId &&
        obj.dataSourceAccountId
          .toLowerCase()
          .includes(filterSearch.toLowerCase()))
    ) {
      return true;
    }
  });

  const onClickHandler = (e, id) => {
    setSelectedDataSourceId(id);
  };

  listOfDataSources.sort(function (a, b) {
    var nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
    var nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  useEffect(() => {
    if (!selectedDataSourceId) {
      // let listOfDataSources = data && data.dataSources ? data.dataSources : [];
      // listOfDataSources.sort(compare);
      const firstDataSourceInList =
        data && data.dataSources && listOfDataSources[0]
          ? listOfDataSources[0]._id
          : '';

      setSelectedDataSourceId(firstDataSourceInList);
    }
  }, [listOfDataSources]);

  let i = 0;
  let userButtons = listOfDataSources.map((obj, index) => {
    let dataSourceRef = '';
    switch (obj.dataSourceRef) {
      case 'GoogleAdsDataSource':
        dataSourceRef = 'Google Ads';
        break;
      case 'FacebookAdsDataSource':
        dataSourceRef = 'Facebook Ads';
        break;
      case 'SnapchatAdsDataSource':
        dataSourceRef = 'Snapchat Ads';
        break;
      case 'TikTokAdsDataSource':
        dataSourceRef = 'TikTok Ads';
        break;

      case 'PinterestAdsDataSource':
        dataSourceRef = 'Pinterest Ads';
        break;
      case 'AmazonDspDataSource':
        dataSourceRef = 'Amazon DSP';
        break;

      case 'ReadpeakDataSource':
        dataSourceRef = 'Readpeak';
        break;

      case 'StrossleDataSource':
        dataSourceRef = 'Strossle';
        break;

      case 'AppleSearchAdsDataSource':
        dataSourceRef = 'Apple Search Ads';
        break;

      case 'MicrosoftInvestDataSource':
        dataSourceRef = 'Microsoft Invest';
        break;

      case 'TaboolaAdsDataSource':
        dataSourceRef = 'Taboola Ads';
        break;

      case 'LinkedInAdsDataSource':
        dataSourceRef = 'LinkedIn Ads';
        break;

      case 'MicrosoftAdvertisingDataSource':
        dataSourceRef = 'Microsoft Advertising';
        break;

      case 'GoogleAnalytics4DataSource':
        dataSourceRef = 'GA4';
        break;
      case 'CM360DataSource':
        dataSourceRef = 'CM360';
        break;
      case 'DV360DataSource':
        dataSourceRef = 'DV360';
        break;

      case 'AdformDataSource':
        dataSourceRef = 'Adform';
        break;
      case 'CustomDataSource':
        dataSourceRef = 'Custom Data Source';
        break;
    }

    i++;

    const subTitle = dataSourceRef + ' ' + obj.dataSourceAccountId;
    return (
      <Button
        key={index}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '60px',
          width: '250px',
          justifyContent: 'flex-start',
          background:
            selectedDataSourceId === obj._id &&
            stylesStoredInReduxManagerAccountSettings.selectedListButton
        }}
        color="primary"
        onClick={(e) => onClickHandler(e, obj._id)}
      >
        <div
          style={{
            display: 'grid',
            color: stylesStoredInReduxManagerAccountSettings.color
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj?.name?.length > 25
              ? obj?.name?.slice(0, 25) + '...'
              : obj?.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {subTitle.length > 35 ? subTitle?.slice(0, 35) + '...' : subTitle}
          </div>
        </div>
      </Button>
    );
  });

  const onChangeHandler = (e) => {
    setFilterSearch(e.target.value);
  };

  // ################################## handle modals ###################################################################

  const [openAddDataSourceModal, setOpenAddDataSourceModal] = useState(false);
  const [openDataSourceTermsModal, setOpenDataSourceTermsModal] =
    useState(true);
  const [renderDataSource, setRenderDataSource] = useState('');

  useEffect(() => {
    // if (code) {
    //   setOpenAddDataSourceModal(true);
    // }
    if (dataSource && code) {
      setOpenAddDataSourceModal(true);
      setRenderDataSource(dataSource);
      setModal(dataSource);
    }
  }, [dataSource, code]);

  let renderModal = '';

  const [modal, setModal] = useState('');

  switch (modal) {
    case 'adform':
      renderModal = (
        <AddAdformDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'googleads':
      renderModal = (
        <AddGoogleAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;
    case 'cm360':
      renderModal = (
        <AddCM360DataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;
    case 'dv360':
      renderModal = (
        <AddDV360DataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;
    case 'googleanalytics':
      renderModal = (
        <AddGoogleAnalytics4DataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;
    case 'facebookads':
      renderModal = (
        <AddFacebookAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;
    case 'snapchatads':
      renderModal = (
        <AddSnapchatAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'tiktokads':
      renderModal = (
        <AddTikTokAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'pinterestads':
      renderModal = (
        <AddPinterestAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'amazonads':
      renderModal = (
        <AddAmazonAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'readpeak':
      renderModal = (
        <AddReadpeakDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'strossle':
      renderModal = (
        <AddStrossleDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'applesearchads':
      renderModal = (
        <AddAppleSearchAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'taboolaads':
      renderModal = (
        <AddTaboolaAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'linkedinads':
      renderModal = (
        <AddLinkedInAdsDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'microsoftadvertising':
      renderModal = (
        <AddMicrosoftAdvertisingDataSourceModal
          open={openAddDataSourceModal}
          setOpen={setOpenAddDataSourceModal}
          // currentManagerAccount={currentManagerAccount}
          setDataSource={setDataSource}
          code={code}
          refetechDataSources={refetch}
          setCode={setCode}
        />
      );
      break;

    case 'adform_terms_and_conditions':
      renderModal = (
        <AdformTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;
    case 'facebook_ads_terms_and_conditions':
      renderModal = (
        <FacebookAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'snapchat_ads_terms_and_conditions':
      renderModal = (
        <SnapchatAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'tiktok_ads_terms_and_conditions':
      renderModal = (
        <TikTokAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'pinterest_ads_terms_and_conditions':
      renderModal = (
        <PinterestAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'amazon_ads_terms_and_conditions':
      renderModal = (
        <AmazonAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'readpeak_terms_and_conditions':
      renderModal = (
        <ReadpeakTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'strossle_terms_and_conditions':
      renderModal = (
        <StrossleTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'apple_search_ads_terms_and_conditions':
      renderModal = (
        <AppleSearchAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          refetechDataSources={refetch}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'microsoft_invest_terms_and_conditions':
      renderModal = (
        <MicrosoftInvestTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          refetechDataSources={refetch}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'taboola_ads_terms_and_conditions':
      renderModal = (
        <TaboolaAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'linkedin_ads_terms_and_conditions':
      renderModal = (
        <LinkedInAdsTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'microsoft_advertising_terms_and_conditions':
      renderModal = (
        <MicrosoftAdvertisingTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;

    case 'google_ads_terms_and_conditions':
    case 'cm360_terms_and_conditions':
    case 'dv360_terms_and_conditions':
    case 'google_analytics_terms_and_conditions':
      renderModal = (
        <GoogleTermsModal
          modal={modal}
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;
    case 'custom_data_source_terms_and_conditions':
      renderModal = (
        <CustomDataSourceTermsModal
          open={openDataSourceTermsModal}
          setOpen={setOpenDataSourceTermsModal}
          // currentManagerAccount={currentManagerAccount}
        />
      );
      break;
  }

  // ####################################################################################################################

  return (
    <div
      style={{
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridTemplateRows: '50px auto'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          height: '100%',
          verticalAlign: 'bottom',
          fontSize: '16px',
          padding: '18px 10px'
        }}
      >
        <StorageIcon style={{ verticalAlign: 'bottom' }} /> Data Connections
      </div>

      <div
        style={{
          overflow: 'hidden',
          borderRadius: '5px',
          display: 'grid',
          gridRowStart: 2,
          gridRowEnd: 2,
          gridTemplateRows: '70px auto',
          height: 'calc(100vh - 143.78px)',
          backgroundColor:
            stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
          backdropFilter: 'blur(10px)',
          margin: '4px',
          // borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            height: '100%',
            justifyContent: 'flex-start',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
            padding: '5px 30px',
            fontSize: '16px'
          }}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '145px 132px' }}>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 1 }}>
              <TextField
                style={{
                  display: 'grid',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  width: '90%'
                }}
                variant="standard"
                id="standard-basic"
                label="Search"
                value={filterSearch}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridColumnStart: 2,
                gridColumnEnd: 2,
                paddingTop: '15px',
                width: '32px'
              }}
            >
              <AddDataSourceButton setOpen={setOpenAddUserModal} />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridRowStart: 2,
            gridRowEnd: 2,
            gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
            padding: '25px 20px',
            overflowY: 'scroll'
          }}
        >
          {userButtons}
        </div>
      </div>
      {renderModal}
      {/* { openAddDataSourceModal && <AddGoogleAdsDataSourceModal open={openAddDataSourceModal} setOpen={setOpenAddDataSourceModal} currentManagerAccount={currentManagerAccount} /> } */}
      <AddDataSourceModal
        refetchAccountList={refetch}
        open={openAddUserModal}
        setOpen={setOpenAddUserModal}
        // currentManagerAccount={currentManagerAccount}
        setModal={setModal}
        setOpenDataSourceTermsModal={setOpenDataSourceTermsModal}
      />
    </div>
  );
}

// export default DataSourcelist

DataSourcelist.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(DataSourcelist);
