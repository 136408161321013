const tableChartWidget = async (
  widget,
  pres,
  slide,
  colorPalette,
  dummySlide
) => {
  // let dataChartObj = {
  //   name: widget.title,
  //   labels: [], // widget.data.map((obj) => obj.label),
  //   values: [] // widget.data.map((obj) => obj.value)
  // };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  //   let rows = [
  //     [
  //         { text: "Top Lft", options: { align: "left", fontFace: "Arial" } },
  //         { text: "Top Ctr", options: { align: "center", fontFace: "Verdana" } },
  //         { text: "Top Rgt", options: { align: "right", fontFace: "Courier" } },
  //     ],
  // ];
  const rowWidthDictionary = {
    impressions: 2
  };

  const borderColor = 'EEEEEE';

  let rows = [
    [
      {
        text: widget.dimensions[0],
        options: {
          align: 'left',
          fontFace: 'Arial',
          bold: true,

          border: [
            {
              type: 'none',
              pt: '1',
              color: 'BCBCBC'
            },
            {
              // type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              // type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              type: 'none',
              pt: '1',
              color: borderColor
            }
          ]
        }
      },
      ...widget.metrics.map((metric, index) => {
        // let width = 1;

        // switch (metric) {
        //   case 'clicks':
        //     width = 1;
        //     break;
        //   case 'impressions':
        //     width = 2;
        //     break;
        // }
        return {
          text: metric[0].toUpperCase() + metric.slice(1),
          options: {
            align: 'right',
            fontFace: 'Arial',
            bold: true,
            w: rowWidthDictionary[metric] || 1,

            border: [
              {
                type: 'none',
                pt: '1',
                color: borderColor
              },
              {
                type: index === widget.metrics.length - 1 && 'none',
                pt: '1',
                color: borderColor
              },
              {
                // type: 'solid',
                pt: '1',
                color: borderColor
              },
              {
                // type: 'solid',
                pt: '1',
                color: borderColor
              }
            ]
          }
        };
      })
    ]
  ];

  let numberOfDecimals = 2;
  let rowIndex = 0;
  for (let row of widget.data) {
    let newRow = [
      {
        text: row['label'],
        options: {
          align: 'left',
          fontFace: 'Arial',
          border: [
            {
              type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: '1',
              color: borderColor
            },
            {
              type: 'none',
              pt: '1',
              color: borderColor
            }
          ]
        }
      }
    ];
    let metricIndex = 0;
    for (let metric of widget.metrics) {
      newRow.push({
        text: row[metric].toLocaleString(undefined, {
          minimumFractionDigits: numberOfDecimals,
          maximumFractionDigits: numberOfDecimals
        }),
        options: {
          align: 'right',
          fontFace: 'Arial',
          // bold: true,
          w: rowWidthDictionary[metric] || 1,

          border: [
            {
              type: 'solid',
              pt: '0',
              color: borderColor
            },
            {
              type: metricIndex === widget.metrics.length - 1 && 'none',
              pt: '0',
              color: borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: '1',
              color: borderColor
            },
            {
              type: 'solid',
              pt: '0',
              color: borderColor
            }
          ]
        }
      });

      metricIndex++;
    }
    rows.push(newRow);

    rowIndex++;
  }
  console.log('rows: ', rows);
  await dummySlide.addTable(rows, {
    // w: 9
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    // rowH: 1,
    align: 'left',
    fontFace: 'Arial',
    fontSize: 6
    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });

  // console.log(
  //   'slide: ',
  //   slide._slideObjects[slide._slideObjects.length - 1].options.h
  // );

  const cardHeight =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;
  const cardHWidth =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;

  console.log(
    'dummySlide._slideObjects[dummySlide._slideObjects.length - 1]: ',
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1]
  );
  // slide._slideObjects[slide._slideObjects.length - 2].options.h = cardHeight;

  console.log('cardHeight: ', cardHeight);
  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 //5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      // h: +widget.height.split('px')[0] / 72 / 2,
      h: Math.round(cardHeight * 1.0),
      // h: 1190625,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  await slide.addTable(rows, {
    // w: 9
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    // rowH: 1,
    align: 'left',
    fontFace: 'Arial',
    fontSize: 8,
    valign: 'middle'
    // colW: [2, ...widget.metrics.map((metric) => 1)]
    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });
};

export default tableChartWidget;
