import React, { useState, useEffect, useContext } from 'react';

import PersonIcon from '@mui/icons-material/Person';

import { useLazyQuery } from '@apollo/client';
import { userInfoAndAccountsQuery } from '../../../../graphql/queries';
import Button from '@mui/material/Button';

import EditButton from './edit-dialog/EditButton';

// import AddButton from './AddButton';
// import EditButton from './EditButton';
// import RemoveButton from './RemoveButton';
// import AddAccountUserRelationModal from './add-account-user-relation-modal/AddAccountUserRelationModal';

import AccountList from './AccountList';

// import TokenList from './token-list/TokenList';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountContext } from '../../../../context/AccountContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import CalendarButton from './calendar/CalendarButton';

import { AuthContext } from '../../../../context/AuthContext';

import UserSettings from './user-settings/UserSettings';

import EmergencyContactSettings from './emergency-contact-settings/EmergencyContactSettings';
import TokensAndConnections from './TokensAndConnections';

import IsSmsWarningsDelayedNighttimeSwitch from './IsSmsWarningsDelayedNighttimeSwitch';
import IsSmsWarningsDelayedOnWeekendsSwitch from './IsSmsWarningsDelayedOnWeekendsSwitch';

import SelectTimeZone from './SelectTimeZone';

function User({
  // currentManagerAccount,
  selectedUser: selectedUserId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { user: currentUser } = useContext(AuthContext);

  const { isDemoMode } = useContext(InterfaceContext);

  // const { selectedUser, setSelectedUser } = useState();

  const variables = {
    userInfoAndAccountsInput: {
      managerAccountId: currentManagerAccount?._id,
      selectedUserId: selectedUserId
    }
  };

  const [
    loadUserInfoAndAccounts,
    { called, loading, data, refetch: refetchUserInfo }
  ] = useLazyQuery(userInfoAndAccountsQuery, {
    variables,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    loadUserInfoAndAccounts();
  }, [currentManagerAccount, selectedUserId]);

  const name = data?.userInfoAndAccounts?.name;
  const email = data?.userInfoAndAccounts?.email;
  const role = data?.userInfoAndAccounts?.role;
  const lastLoginAt = data?.userInfoAndAccounts?.lastLoginAt
    ? new Date(+data?.userInfoAndAccounts?.lastLoginAt).toLocaleDateString()
    : null;

  const loginLogsCountLast14Days =
    data?.userInfoAndAccounts?.loginLogsCountLast14Days;

  const accounts = data?.userInfoAndAccounts?.accounts
    ? data.userInfoAndAccounts.accounts
    : [];
  const tokens = data?.userInfoAndAccounts?.tokens || [];

  const userInfoAndAccounts = data?.userInfoAndAccounts;

  // const selectedUserId = data?.userInfoAndAccounts?._id
  //   ? data?.userInfoAndAccounts?._id
  //   : '';

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  accounts.sort(compare);

  var clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let maxWidth = '';
  let minWidth = '';

  return (
    // <div style={{display: 'grid', gridColumnStart: 2, gridColumnEnd: 2, gridTemplateRows: '50px repeat(auto-fill, minmax(46px, 46px))'}}>
    <div
      style={{
        overflow: 'hidden',
        display: 'grid',
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridTemplateRows: '50px auto'
        // height: 'calc(100vh - 143.78px)'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          fontSize: '16px',
          padding: '15px 10px'
        }}
      >
        {/* <SettingsIcon style={{verticalAlign: 'bottom'}} /> Settings */}
      </div>

      <div
        style={{
          overflow: 'hidden',
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: 2,
          gridRowEnd: 3,
          gridTemplateRows: '70px auto',
          margin: '4px',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
            gridTemplateColumns: 'auto 100px',
            gridTemplateRows: '50px 20px',
            height: '100%',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
            backdropFilter: 'blur(10px)',
            borderRadius: '5px 5px 0px 0px'
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
              borderRadius: '5px 0px 0px 0px'
            }}
          >
            <div style={{ padding: '25px 30px', fontSize: '16px' }}>
              <PersonIcon style={{ verticalAlign: 'bottom' }} />
              <span style={{ marginLeft: '10px' }}>{name}</span>
            </div>
          </div>
          <div
            style={{
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor
            }}
          >
            <div style={{ fontSize: '10px' }}>
              <span style={{ paddingLeft: '55px', marginLeft: '10px' }}>
                Role: {role}
              </span>{' '}
              <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Email: {email}
              </span>
              {lastLoginAt && (
                <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                  LLA: {lastLoginAt}
                </span>
              )}
              {lastLoginAt && (
                <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                  Log in count last 14d: {loginLogsCountLast14Days}
                </span>
              )}
            </div>
          </div>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 2,
              gridColumnEnd: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              // justifyContent: 'flex-end',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor
              // paddingTop: '9px'
            }}
          >
            {/* <EditButton
              name={name}
              email={email}
              selectedUser={selectedUser}
              role={role}
            /> */}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // gridColumnStart: 1,
            // gridColumnEnd: 1,
            gridRowStart: 3,
            gridRowEnd: 3,
            // gridTemplateRows: '70px repeat(auto-fill, minmax(60px, 60px))',
            height: 'calc(100vh - 213.78px)',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
            backdropFilter: 'blur(10px)',
            borderRadius: '0px 0px 5px 5px',
            overflowY: 'scroll'
            // width: '100%'
          }}
        >
          <UserSettings
            userInfoAndAccounts={userInfoAndAccounts}
            refetchUserInfo={refetchUserInfo}
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
          />

          <EmergencyContactSettings
            userInfoAndAccounts={userInfoAndAccounts}
            refetchUserInfo={refetchUserInfo}
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
              paddingLeft: '40px'
            }}
          >
            <div style={{ width: '760px' }}>
              <IsSmsWarningsDelayedNighttimeSwitch
                userInfoAndAccounts={userInfoAndAccounts}
                refetchUserInfo={refetchUserInfo}
              />
            </div>
            <div style={{ width: '760px' }}>
              <IsSmsWarningsDelayedOnWeekendsSwitch
                userInfoAndAccounts={userInfoAndAccounts}
                refetchUserInfo={refetchUserInfo}
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '50px',
              paddingLeft: '40px'
            }}
          ></div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '50px',
              marginBottom: isDemoMode && '10px',
              paddingLeft: '40px'
            }}
          >
            <div style={{ width: '760px' }}>
              <SelectTimeZone
                userInfoAndAccounts={userInfoAndAccounts}
                refetchUserInfo={refetchUserInfo}
              />
            </div>
          </div>

          {(['system_admin'].includes(currentUser?.role) ||
            ['6167f2a56a44185b71fd0bcc', '61a917e9d86ba532fcee25a9'].includes(
              currentUser?._id
            )) &&
            !isDemoMode && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '100px',
                  paddingLeft: '40px'
                }}
              >
                <div style={{ width: '760px' }}>
                  <CalendarButton />
                </div>
              </div>
            )}

          <TokensAndConnections
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
            selectedUserId={selectedUserId}
          />
          {/* <TokenList
            tokens={tokens}
            selectedUserId={selectedUserId}
            refetchUserInfo={refetchUserInfo}
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
          /> */}
          <AccountList
            accounts={accounts}
            // currentManagerAccount={currentManagerAccount}
            selectedUserId={selectedUserId}
            refetchUserInfo={refetchUserInfo}
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
          />
        </div>
      </div>
    </div>
  );
}

// export default User

User.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(User);
