import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportAssetsQuery } from '../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

import ReportAsset from './Asset';

import UploadAssetsButton from './upload-button/UploadAssetsButton';
import UploadReportAssetsDialog from './upload-button/UploadReportAssetsDialog';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

function ReportAssets({
  selectedReportAssetList,
  setSelectedReportAssetList,
  selectedReportAsset,
  setSelectedReportAsset
}) {
  const theme = useTheme();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const [reportAssets, setReportAssets] = useState();

  const { refetchAssets, setRefetchAssets } = useContext(ReportBuilderContext);
  // const [selectedReportAsset, setSelectedReportAsset] = useState();
  // console.log('selectedReport: ', selectedReport);

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      reportAssetListType: selectedReportAssetList?.type
      // reportId: selectedReport?._id,
      // reportType: selectedReport?.type
    }
  };

  const [loadReportAssets, { called, loading, data, refetch }] = useLazyQuery(
    reportAssetsQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    console.log('debug9924>row52');
    if (currentManagerAccountId && selectedReportAssetList) {
      loadReportAssets();
    }
  }, [
    currentManagerAccountId,
    currentAccountIds,
    selectedReportAssetList,
    selectedReportAssetList?.type
  ]);

  useEffect(() => {
    if (data?.reportAssets && called) {
      setReportAssets(data?.reportAssets);
    }
  }, [data]);
  console.log('reportAssets: ', reportAssets);

  useEffect(() => {
    if (refetchAssets) {
      refetch();
    }
  }, [refetchAssets]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        // background: 'red',
        // display: 'flex',
        // flexDirection: 'row',
        // margin: '0px 10px',
        padding: '10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(0,0,0,0.1)'
            : 'rgba(255,255,255,0.3)',
        overflow: 'scroll'
      }}
    >
      <UploadAssetsButton />
      {(reportAssets || []).map((reportAsset) => (
        <ReportAsset
          reportAsset={reportAsset}
          selectedReportAsset={selectedReportAsset}
          setSelectedReportAsset={setSelectedReportAsset}
        />
      ))}
      <UploadReportAssetsDialog />
    </div>
  );
}

export default ReportAssets;
